import axios from "../../axios-wagx-web";
import axiosLib from "axios";
import { appCloseTab, manageError } from "../../functions/utility";

export const updateObjectData = (actionProps, object, component) => {
  const urlToCall = actionProps.coreFunction ? actionProps.functionName : "/views/" + component.props.viewId + "/" + actionProps.functionName;
  let postObject = {
    "object": object,
    "fieldMapping": actionProps.fieldMapping
  };

  component.props.showLongOperationWaitingModal();
  axios.post(urlToCall, postObject)
    .then(response => {
      component.onUpdateObjectDataResponseHandler(response);
      component.props.hideLongOperationWaitingModal();
    }).catch((exception) => {
      component.props.hideLongOperationWaitingModal();
      if (!axiosLib.isCancel(exception)) {
        manageError("WagxActionFunction - updateObjectData", null, exception);
      }
    });
}

export const executeCustomFunction = (actionProps, object, component) => {
  const urlToCall = actionProps.coreFunction ? actionProps.functionName : "/views/" + component.props.viewId + "/" + actionProps.functionName;
  let postObject = {
    "object": object,
    "fieldMapping": actionProps.fieldMapping
  };

  if (component.props.onCustomActionStartHandler != null && component.props.onCustomActionEndHandler) {
    component.props.onCustomActionStartHandler();
  } else {
    component.props.showLongOperationWaitingModal();
  }

  axios.post(urlToCall, postObject)
    .then(response => {
      if (component.props.onCustomActionStartHandler != null && component.props.onCustomActionEndHandler) {
        component.props.onCustomActionEndHandler(response, object);
      } else {
        component.props.hideLongOperationWaitingModal();
      }
    }).catch((exception) => {
      if (component.props.onCustomActionStartHandler != null && component.props.onCustomActionEndHandler) {
        component.props.onCustomActionEndHandler(null);
      } else {
        component.props.hideLongOperationWaitingModal();
      }
      if (!axiosLib.isCancel(exception)) {
        manageError("WagxActionFunction - executeCustomAction", null, exception);
      }
    });
}

export const reloadOpenerAndCloseTab = () => {
  try {
    if (window.opener != null && window.opener.location != null && window.opener.location !== window.location) {
      window.opener.location.reload();
    }
  } catch (error) {
    console.log(error);
  }
  appCloseTab();
}