import authReducer from './auth';
import syncReducer from './sync';
import viewReducer from './view';
import modalReducer from './modal';
import menuReducer from './menu_section';
import calendarReducer from './calendar';
import searchReducer from './search';
import pageReducer from './page';
import genericFormReducer from './genericForm';
import dataTableReducer from './dataTable';
import homeOperativaReducer from './home_operativa';
import customComponentReducer from './customComponent';
import iframeModalRedurcer from './iFrameModal';
import wagxSidePanelRedurcer from './wagxSidePanel';
import errorBoundary from './errorBoundary';
import pivotReducer from './pivot';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

const reducer = (history, syncName) => combineReducers({
  router: connectRouter(history),
  sync: syncReducer(syncName),
  pivot: pivotReducer,
  auth: authReducer,
  view: viewReducer,
  modal: modalReducer,
  menu: menuReducer,
  calendar: calendarReducer,
  search: searchReducer,
  page: pageReducer,
  genericForm: genericFormReducer,
  dataTable: dataTableReducer,
  homeOperativa: homeOperativaReducer,
  customComponent: customComponentReducer,
  iFrameModal: iframeModalRedurcer,
  wagxSidePanel: wagxSidePanelRedurcer,
  errorBoundary: errorBoundary
});


// const rootReducer = (state, action) => {
//     if (action && action.type === 'LOGOUT') {
//       state = undefined;
//     }

//     return reducer(state, action);
//   }
// export default rootReducer;
export default reducer;
