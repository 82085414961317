import React from 'react';
import './ConfirmModal.css';
import intl from 'react-intl-universal'
import { DefaultButton, Modal, Icon, Spinner } from 'office-ui-fabric-react';
import { MessageSeverityEnum } from '../../enums/MessageSeverityEnum';

const ConfirmModal = (props) => {
  const confirmModalTitle = props.confirmTitle != null
    ? props.confirmTitle
    : intl.get(MessageSeverityEnum.INFO.titleKey).d(MessageSeverityEnum.INFO.titleDefault);

  const containerClassName = ["confirm-modal-container"];
  let confirmModalContent = null;

  if (props.requestInProgress === true) {
    containerClassName.push("is-request-in-progress");

    confirmModalContent = (
      <Spinner />
    );
  } else {
    confirmModalContent = (
      <React.Fragment>
        <div className="confirm-modal-header">
          <label>{confirmModalTitle}</label>
        </div>
        <div className="confirm-modal-body">
          <div className="icon-container">
            <div className="question"><Icon iconName="StatusCircleQuestionMark" /></div>
          </div>
          <div className="message-list-container">
            {props.confirmMessage}
          </div>
        </div>
        <div className="confirm-modal-footer">
          {
            props.onConfirm
              ? (
                <DefaultButton
                  className="primary"
                  onClick={props.onConfirm}
                >
                  {props.confirmText != null ? props.confirmText : intl.get("CRUDComponent.ConfirmModal.confirm").d("Conferma")}
                </DefaultButton>
              )
              : null
          }
          {
            props.onCancel
              ? (
                <DefaultButton
                  className="secondary"
                  onClick={props.onCancel}
                >
                  {props.cancelText != null ? props.cancelText : intl.get("CRUDComponent.ConfirmModal.cancel").d("Annulla")}
                </DefaultButton>
              )
              : null
          }
        </div>
      </React.Fragment>
    );
  }

  return (
    <Modal
      isOpen={true}
      isDarkOverlay={true}
      className="confirm-modal"
      containerClassName={containerClassName.join(" ")}
      scrollableContentClassName="confirm-modal-scrollable-content"
    >
      {confirmModalContent}
    </Modal>
  );
}

export default React.memo(ConfirmModal);