import { updateObject } from '../utils'
import * as actionTypes from '../actions/action-types';

const initialState = {

};

const setHomeOperativaState = (state, action) => {
  return updateObject(state, action.state);
}

const logout = (state) => {
  return updateObject(state, initialState);
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_HOME_OPERATIVA_STATE: return setHomeOperativaState(state, action);
    case actionTypes.LOGOUT: return logout(state, action);
    default:
      return state;
  }
};

export default reducer;