import React from "react";
import { Label } from 'office-ui-fabric-react/lib/Label';
import './TextWithBorder.css';

const TextWithBorder = (props) => {

  if (props.text) {
    return (
      <div style={props.style} className={"textWithBorder " + (props.className ? " " + props.className : "")}>
        <Label title={props.title}>
          {props.text}
        </Label>
      </div>
    )
  } else {
    return null;
  }
}

export default React.memo(TextWithBorder);