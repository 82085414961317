import { updateObject } from '../utils'
import * as actionTypes from '../actions/action-types';

const initialState = {
  isOpen: false,
  url: null,
  title: null,
  width: null,
  height: null,
  updatePageOnClose: null
};

const showIframeModal = (state, action) => {
  return updateObject(state, {
    isOpen: true,
    url: action.url,
    title: action.title ? action.title : null,
    width: action.width ? action.width : null,
    height: action.height ? action.height : null,
    updatePageOnClose: action.updatePageOnClose ? action.updatePageOnClose : null
  });
}

const hideIframeModal = (state, action) => {
  return updateObject(state, {
    isOpen: false
  });
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_IFRAME_MODAL: return showIframeModal(state, action);
    case actionTypes.HIDE_IFRAME_MODAL: return hideIframeModal(state, action);
    default:
      return state;
  }
};

export default reducer;