import * as actionTypes from "./action-types";

export const showModal = (modalProps) => {
    return {
        type: actionTypes.SHOW_MODAL,
        ...modalProps
    }
}

export const showConfirmModal = (modalProps) => {
    return {
        type: actionTypes.SHOW_CONFIRM_MODAL,
        ...modalProps
    }
}

export const dismissModal = (onDismissedHandler) => {
    return {
        type: actionTypes.DISMISS_MODAL,
        onDismissedHandler: onDismissedHandler
    }
}