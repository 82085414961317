import React, { Component } from 'react';
import axios from '../../axios-wagx-web';
import axiosLib from "axios";
import { ComboBox } from 'office-ui-fabric-react';
import intl from 'react-intl-universal';
import { getLocalizedProperty } from '../../LocaleUtils';
import { computeDependsOnFieldsUrl } from '../../functions/utility';

class RadioButton extends Component {

  state = {
    items: [],
    itemsLoaded: false,
    isInError: false,
    filter: ""
  }
  filterHandler = null;
  filterInputHandler = null;
  textInput = null;

  componentDidMount() {
    if (!this.state.itemsLoaded) {
      this.loadData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let shouldReloadItems = false;
    if (prevProps.dependsOnFields && this.props.dependsOnFields.length) {
      if (prevProps.dependsOnFields.length !== this.props.dependsOnFields.length) {
        shouldReloadItems = true;
      } else {
        this.props.dependsOnFields.forEach((dependsOnField, index) => {
          const prevDependsOnField = prevProps.dependsOnFields[index];
          if (dependsOnField.value !== prevDependsOnField.value) {
            shouldReloadItems = true;
          }
        });
      }
    }
    if (shouldReloadItems) {
      this.loadData();
    }
    if (
      (this.props.useLiveSearch === undefined || this.props.useLiveSearch === null) ||
      this.props.useLiveSearch === true
    ) {
      if (this.textInput) {
        setTimeout(() => {
          if (this.textInput != null) {
            this.textInput.focus();
          }
        }, 10);
      }
    }
  }

  componentWillMount = () => {
    if (this.filterHandler != null) {
      clearTimeout(this.filterHandler);
    }
  }

  loadData = () => {
    this.requestCancelToken = axiosLib.CancelToken.source();
    let value = "";
    let items;
    let url = "lovs/" + this.props.lovId + "?";
    if (this.props.lovId) {
      if (this.props.applyFilters) {
        //filtri che arrivano dai link
        this.props.applyFilters.forEach(element => {
          let param = element.param;
          const remappedFilter = this.props.remapAppliedFiltersFields.find((remap) => remap.from === param);
          if (remappedFilter) {
            url += "&" + remappedFilter.to + "=" + element.value;
          }
        });
      }

      const computedDependsOnFieldsObj = computeDependsOnFieldsUrl(url, this.props.dependsOnFields);
      url = computedDependsOnFieldsObj.url;

      axios.get(url, { cancelToken: this.requestCancelToken.token })
        .then(response => {
          this.requestCancelToken = null;
          if (response.data.success) {
            let list = response.data.value;
            items = [...list.map(item => {
              return { id: item.id, key: item.value, text: item.description };
            })];
            try {
              value = items[items.findIndex((item) => item.id === this.props.value || item.key === this.props.value)].key;
            } catch (exception) {
              console.error(exception);
            }
            this.props.onChange({ target: { value: value } }, this.props.name);
            this.setState({ items: items, itemsLoaded: true });
          } else {
            this.setState({ itemsLoaded: true });
          }
        })
        .catch(exception => {
          if (!axiosLib.isCancel(exception)) {
            this.setState({ isInError: true });
          }
        });
    }
    if (this.props.lovValue) {
      items = [items, ...this.props.lovValue.map(item => {
        return { id: null, key: item.value, text: getLocalizedProperty(item, "description") };
      })];
      try {
        value = items[items.findIndex((item) => item.id === this.props.value || item.key === this.props.value)].key;
      } catch (exception) {
        console.error(exception);
      }
      this.props.onChange({ target: { value: value } }, this.props.name);
      this.setState({ items: items, itemsLoaded: true });
    }
  }


  componentWillUnmount() {
    if (this.requestCancelToken != null) {
      this.requestCancelToken.cancel();
      this.requestCancelToken = null;
    }
  }

  onChangeHandler = (event, option, index, value) => {
    this.props.onChange(event, this.props.name, option.key);
  }

  render() {

    let value = "";

    if (this.state.isInError) {
      value = { id: "SelectFieldError", key: "SelectField.errorLoadingData", text: intl.get("SelectField.errorLoadingData").d("--Impossibile caricare i dati--") };
    } else if (!this.state.itemsLoaded) {
      value = { id: "SelectFieldLoading", key: "SelectField.dataLoading", text: intl.get("SelectField.loadingInProgress").d("--Caricamento dati...--") };
    } else {
      try {
        value = this.state.items[this.state.items.findIndex((item) => item.id === this.props.value || item.key === this.props.value)];
      } catch (exception) {
        // console.error(exception);
      }
    }

    let disabled = false;
    if (!this.state.itemsLoaded || this.state.isInError) {
      disabled = true;
    } else if (this.props.forcedValue === true) {
      disabled = true;
    } else if (this.props.disabled) {
      disabled = true;
    }

    let selectedKey = value && value.key ? value.key : "";

    const props = {
      disabled: disabled,
      label: this.props.label,
      errorMessage: this.props.formFeedBack,
      options: this.filterItems(this.state.items),
      className: this.props.className,
      onChange: this.onChangeHandler,
      required: this.props.required,
      defaultSelectedKey: selectedKey
    }

    return (<ComboBox {...props} />);
  }

  filterItems = (items) => {
    if (!this.state.filter || this.state.filter === "") {
      return items;
    } else {
      let filteredItems = [];
      items.forEach(item => {
        if (item && item.text && item.text.toLowerCase().indexOf(this.state.filter.toLowerCase()) !== -1) {
          filteredItems.push(item);
        }
      });
      return filteredItems;
    }
  }
}

export default RadioButton;