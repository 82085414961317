import * as actionTypes from "./action-types";
export const expandMenu = (menu) => {
    return {
        type: actionTypes.EXPAND_MENU,
        menu: menu
    }
}
export const collapseMenu = (menu) => {
    return {
        type: actionTypes.COLLAPSE_MENU,
        menu: menu
    }
}
export const toggleMenuBar = (menu) => {
    return {
        type: actionTypes.TOGGLE_MENU_BAR,
        menu: menu
    }
}
const setActiveMenu = (menu) => {
    return {
        type: actionTypes.SET_ACTIVE_MENU,
        menu: menu
    }
}
export const openMenu = (menu) => {
    return {
        type: actionTypes.OPEN_MENU,
        menu: menu
    }
}
export const firstOpen = (menu) => {
    return {
        type: actionTypes.FIRST_OPEN,
        menu: menu
    }
}
export const setOpenMenu = (menu) => {
    return dispatch =>{
        dispatch(setActiveMenu(menu));
    }
}