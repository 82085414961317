import { generateArrayFilters } from "../../functions/utility";
import { DateRangeValue } from "../../UI/DatePicker/DateRangePicker";
import { CurrencyRangeValue } from "../CurrencyInput/CurrencyRangeInput";
import { CURRENCY_RANGE, DATE_RANGE } from "../GenericInput/GenericInputTypes";
import * as formatters from '../../functions/formatters';
import { isNotNullOrEmpty } from "../../functions/StringUtils";

export const generateUrl = (param) => {
  let queryString = '';
  const {viewState, filterObject, pagination, queryStringParam, orderBy, order, addOrderBy} = param;
  
  if (pagination) {
    let currentPage = 0;
    if (viewState && viewState.page) {
      currentPage = viewState.page;
    }
    queryString += '&page=' + currentPage;
    queryString += '&size=' + pagination.pageSize;
  }
  if (addOrderBy === true && isNotNullOrEmpty(orderBy)) {
    queryString += '&sort=' + orderBy + "," + order;
  }
  if (viewState) {
    for (const key in viewState.filters) {
      if (viewState.filters.hasOwnProperty(key)) {
        const element = viewState.filters[key];
        if (element.value != null && element.value !== "") {
          let value;
          let alreadyInQueryParam = false;
          switch (element.type) {
            case "Date":
            case "date":
              formatDateFilterValue(element);
              value = generateArrayFilters(element);
              break;
            case DATE_RANGE:
              value = new DateRangeValue(element.value);
              value.fromDate = value.fromDate != null ? formatters["momentDateToStringFormatter"](value.fromDate) : null;
              value.toDate = value.toDate != null ? formatters["momentDateToStringFormatter"](value.toDate) : null;
              value = value.toString();
              break;
            case CURRENCY_RANGE:
              value = new CurrencyRangeValue(element.value);
              value = value.toString();
              break;
            case "Select":
            case "select":
              if (Array.isArray(element.value)) {
                for (let i = 0; i < element.value.length; i++) {
                  let val = element.value[i];
                  queryString += '&' + key + '=' + encodeURIComponent(val);
                }
                alreadyInQueryParam = true;
              } else {
                value = "EQ(" + element.value + ")";
              }
              break;
            case "int":
            case "Int":
            case "float":
              value = generateArrayFilters(element);
              break;
            default:
              if (Array.isArray(element.value)) {
                for (let i = 0; i < element.value.length; i++) {
                  let val = element.value[i];
                  queryString += '&' + key + '=' + encodeURI(val);
                }
                alreadyInQueryParam = true;
              } else {
                value = element.value;
              }
          }
          if (!alreadyInQueryParam) {
            queryString += '&' + key + '=' + encodeURIComponent(value);
          }
        }
      }
    }

    if (viewState.applyFilters) {
      //filtri che arrivano dai link
      viewState.applyFilters.forEach(element => {
        const formattedElement = JSON.parse(JSON.stringify(element));
        let value = "";
        if (Array.isArray(formattedElement.value)) {
          formatDateFilterValue(formattedElement);
          value = generateArrayFilters(formattedElement);
        } else if (isNotNullOrEmpty(formattedElement.value)) {
          formatDateFilterValue(formattedElement);
          value = formattedElement.value;
        }

        if (isNotNullOrEmpty(value)) {
          queryString += '&' + element.param + '=' + encodeURIComponent(value);
        }
      });
    }
  }
  if (queryStringParam != null) {
    queryString += queryStringParam;
  }
  if (filterObject != null) {
    for (let key in filterObject) {
      if (!queryString.includes(key)) {
        queryString += "&" + key + "=" + filterObject[key].value;
      }
    }
  }

  return queryString;
}

export const formatDateFilterValue = (element) => {
  if (element.type === "Date" || element.type === "date") {
    if (Array.isArray(element.value)) {
      for (let i = 0; i < element.value.length; i++) {
        if (element.value[i]) {
          element.value[i] = formatters["momentDateToStringFormatter"](element.value[i]);
        }
      }
    } else {
      element.value = formatters["momentDateToStringFormatter"](element.value);
    }
  }
}

/**
 * metodo per passare ordinamento della dataTable ad altre pagine. 
 * Il mandatory sort viene calcolato dal FE che lo recupera dalla stuttura
 * */
export const computeTableSort = (mandatorySort, defaultSort, orderBy, order) => {
  let tableSort = [];

  tableSort.concat(computeSort(mandatorySort));

  const computedDefaultSort = computeSort(defaultSort);
  let computedSort = [];
  if(isNotNullOrEmpty(orderBy) && isNotNullOrEmpty(order)){
    computedSort = computeSort(orderBy+","+order);
  }
  

  if(computedSort.length > 0) {
    tableSort = addMissingSortField(tableSort, computedSort);
  } else if (computedSort.length === 0 && computedDefaultSort.length > 0) {
    tableSort = addMissingSortField(tableSort, computedDefaultSort);
  }

  return tableSort;
}

/**
 * sort deve essere formattato così: CAMPO_1,asc;CAMPO_2,desc; per essere compatibile col metodo
 */
export const computeSort = (sort) => {
  let computedSort = [];
  if(isNotNullOrEmpty(sort)) {
    let splittedSort = sort.split(";");
    if(splittedSort.length > 0) {
      for(let i = 0; i < splittedSort.length; i++){
        const split = splittedSort[i].split(",");
        computedSort.push({orderBy: split[0], order: split[1]});
      }
    }
  }

  return computedSort;
}

const addMissingSortField = (sort, sortsToAddList) => {
  const resultSortList = [];
  sortsToAddList.forEach(sortToAdd => {
    const add = !sort.some( obj => obj.order === sortToAdd.order);
    if(add) {
      resultSortList.push(sortToAdd);
    }
  });

  return sort.concat(resultSortList);
}