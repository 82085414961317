import axios from 'axios';
import { store } from '../store-config';
import * as actions from './store/actions';

const configuration = window.APP_CONFIG;

axios.defaults.headers['Content-Type'] = 'application/json';
const instance = axios.create({
  baseURL: configuration.baseURL,
  withCredentials: true
});


let updateSessionTimeoutTimeout = null;

export const setupSessionInterval = () => {
  instance.interceptors.response.use((response) => {
    if (updateSessionTimeoutTimeout != null) {
      clearTimeout(updateSessionTimeoutTimeout);
      updateSessionTimeoutTimeout = null;
    }

    updateSessionTimeoutTimeout = setTimeout(() => {
      const sessiontimeout = parseInt(response.headers.sessiontimeout, 10);
      store.dispatch(actions.updateSessionTimeout(sessiontimeout));
      updateSessionTimeoutTimeout = null;
    }, 3000);

    return response;
  });
  setInterval(() => {
    const sessiontimeout = store.getState().sync.sessiontimeout;
    if (sessiontimeout) {
      const currentDate = new Date().getTime();
      const diff = sessiontimeout - currentDate;
      if (diff < 300000 && !store.getState().sync.showSessionWarningDialog) {
        store.dispatch(actions.sessionWarningDialog(true));
      } else if (diff <= 0 && store.getState().sync.showSessionWarningDialog) {
        store.dispatch(actions.setSessionEnded(true));
      } else if (diff > 300000 && store.getState().sync.showSessionWarningDialog) {
        store.dispatch(actions.sessionWarningDialog(false));
      }
    }
  }, 60000);
}

export const cleanRequestCancelToken = (requestCancelToken) => {
  //Pulizia token Axios
  if (requestCancelToken != null) {
    requestCancelToken.cancel();
    requestCancelToken = null;
  }

  return requestCancelToken;
}



export default instance;