import { updateObject } from '../utils'
import * as actionTypes from '../actions/action-types';

const initialState = {
  viewState: {},
  loading: false,
  longOperationWaitingModal: false,
  percent: null,
  percentTitle: '',
  percentDescription: ''
};

const saveViewState = (state, action) => {
  return updateObject(state, { viewState: action.viewState });
}

const logout = (state, action) => {
  return updateObject(state, initialState);
}

const showLongOperationWaitingModal = (state, action) => {
  return updateObject(state, { 
    longOperationWaitingModal: true, 
    percent: action.percent ,
    percentTitle: action.percentTitle,
    percentDescription: action.percentDescription,
  });
}

const hideLongOperationWaitingModal = (state, action) => {
  return updateObject(state, { longOperationWaitingModal: false, percent: null });
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGOUT: return logout(state, action);
    case actionTypes.SAVE_VIEW_STATE: return saveViewState(state, action);
    case actionTypes.SHOW_LONG_OPERATION_WAITING_MODAL: return showLongOperationWaitingModal(state, action);
    case actionTypes.CLOSE_LONG_OPERATION_WAITING_MODAL: return hideLongOperationWaitingModal(state, action);
    default:
      return state;
  }
};

export default reducer;