import React, { Component } from 'react'
import { TagPicker, TextField } from 'office-ui-fabric-react';
import axios from '../../axios-wagx-web';
import intl from 'react-intl-universal';
import './WagxAutocomplete.css';
import { computeDependsOnFieldsUrl } from '../../functions/utility';

class WagxAutocomplete extends Component {

  constructor(props) {
    super(props);
    this._picker = React.createRef();

    this.isFocused = false;
    this.cleanOnDisabledChange = this.props.cleanOnDisabledChange != null ? this.props.cleanOnDisabledChange : true;
    this.allowManualValue = this.props.allowManualValue != null ? this.props.allowManualValue : true;
    this.cleanOnMount = this.props.cleanOnMount != null ? this.props.cleanOnMount : false;
    this.state = {
      inputValue: null,
      selectedItems: [],
      loading: false
    }

    if (this.props.value) {
      const propsItem = this.cleanOnMount ? null : this.getItemFromPropsValue();
      const item = this._onChangeHandler(propsItem);
      this.state.selectedItems = item;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.cleanOnDisabledChange && this.props.disabled !== prevProps.disabled) {
      const item = this._onChangeHandler([]);
      this.setState({ selectedItems: item, loading: false });
    } else if (!this.state.loading) {
      if (this.props.value !== prevProps.value) {
        this.setState({ loading: true }, () => {
          const propsItem = this.getItemFromPropsValue();
          const item = this._onChangeHandler(propsItem);
          this.setState({ selectedItems: item, loading: false });
        });
      } else if (this.isFocused === false) {
        let shouldReloadItems = false;
        if (prevProps.dependsOnFields && this.props.dependsOnFields.length) {
          if (prevProps.dependsOnFields.length !== this.props.dependsOnFields.length) {
            shouldReloadItems = true;
          } else {
            this.props.dependsOnFields.forEach((dependsOnField, index) => {
              const prevDependsOnField = prevProps.dependsOnFields[index];
              if (dependsOnField.value !== prevDependsOnField.value) {
                shouldReloadItems = true;
              }
            });
          }
        }

        if (shouldReloadItems) {
          this.setState({ loading: true });
          this._onResolveSuggestionsHandler(this.props.value, null, true, true).then((response) => {
            const item = this._onChangeHandler(response);

            if (this.props.removeInvalidField && item.length === 0) {
              this.props.removeInvalidField(this.props.inputId, this.props.field);
            }

            this.setState({ selectedItems: item, loading: false });
          });
        }
      }
    }
  }

  getItemFromPropsValue = () => {
    let item = [];
    if (this.props.value) {
      item = [{ key: this.props.value, name: this.props.value }];
    }
    return item;
  }

  getItemFromInputValue = () => {
    let item = [];
    if (this.allowManualValue === true && this.state.inputValue != null) {
      item = [{ key: this.state.inputValue, name: this.state.inputValue }];
    } else if (this.allowManualValue === false && this.state.inputValue != null && this.state.inputValue !== "") {
      if (this.props.addInvalidField != null) {
        this.props.addInvalidField(this.props.inputId, this.props.field, intl.get("WagxAutocomplete.notAllowedValue").d("Valore non consentito"));
      }
    }
    return item;
  }

  _onResolveSuggestionsHandler = (filterText, tagList, isEquals = false, shouldReloadItems = false) => {
    return new Promise((resolve) => {
      let items = null;
      let minChars = null;

      if (this.props.minChars != null) {
        minChars = this.props.minChars;
      }
      if (shouldReloadItems || minChars == null || (minChars != null && filterText.length >= minChars)) {
        let isFilterPresent = false;
        let url = "lovs/" + this.props.lovId + "?";

        if (filterText && !shouldReloadItems) {
          isFilterPresent = true;
          url += "&" + encodeURIComponent(this.props.param) + "=" + (isEquals ? "EQ(" + encodeURIComponent(filterText) + ")" : encodeURIComponent(filterText));
        }

        if (this.props.applyFilters) {
          //filtri che arrivano dai link
          this.props.applyFilters.forEach(element => {
            let param = element.param;
            const remappedFilter = this.props.remapAppliedFiltersFields.find((remap) => remap.from === param);
            if (remappedFilter) {
              isFilterPresent = true;
              url += "&" + encodeURIComponent(remappedFilter.to) + "=" + encodeURIComponent(element.value);
            }
          });
        }

        const computedDependsOnFieldsObj = computeDependsOnFieldsUrl(url, this.props.dependsOnFields);
        url = computedDependsOnFieldsObj.url;
        isFilterPresent = isFilterPresent || computedDependsOnFieldsObj.isFilterPresent;

        if (isFilterPresent) {
          const curStateVal = this.state.inputValue != null
            ? ('' + this.state.inputValue).toUpperCase()
            : "";
          const curPropsVal = this.props.value != null
            ? ('' + this.props.value).toUpperCase()
            : "";

          axios.get(url)
            .then(response => {
              items = response.data.value.map(item => { return { key: item.value, name: item.description } });

              if (items.length > 1 && (curStateVal !== "" || curPropsVal !== "") && (this.isFocused === false || shouldReloadItems === true)) {
                const filteredItems = items.filter((item) => {
                  const curItem = ('' + item.key).toUpperCase();
                  return (curItem === curStateVal || curItem === curPropsVal);
                });

                if (filteredItems.length === 1) {
                  items = filteredItems;
                }
              }
              if (this.isFocused === false && shouldReloadItems === false && items.length === 1) {
                this._onChangeHandler(items);
              }

              resolve(items);
            })
            .catch(error => {
              items = [{ key: null, name: intl.get("WagxAutocomplete.loadingError").d("--Errore durante il caricamento--") }];
              resolve(items);
            });
        } else {
          items = this.getItemFromPropsValue();
          resolve(items);
        }

      } else {
        resolve(items);
      }
    });
  };

  _onFocusHandler = () => {
    this.isFocused = true;
  }

  _onBlurHandler = () => {
    this.isFocused = false;
    const inputItem = this.getItemFromInputValue()
    this._onChangeHandler(inputItem);
  };

  _onChangeHandler = (item) => {
    this.isFocused = false;
    let selectedItem = [];

    if (item && item.length === 1) {
      selectedItem = item;
      this.props.onChange(null, this.props.field, selectedItem[0].key, null, this.props.formId);
    } else {
      this.props.onChange(null, this.props.field, "", null, this.props.formId);
    }

    if (this.props.removeInvalidField != null) {
      if (this.allowManualValue === false && (selectedItem.length > 0 || this.state.inputValue == null || this.state.inputValue === "")) {
        this.props.removeInvalidField(this.props.inputId, this.props.field);
      }
    }

    return selectedItem;
  };

  _onInputChangeHandler = (inputValue) => {
    this.setState({ inputValue: inputValue });
    return inputValue;
  };

  render() {
    const className = ["WagxAutocomplete"];
    if (this.props.isValid === false || (this.props.formFeedBack != null && this.props.formFeedBack !== "")) {
      className.push("invalidBox");
    }

    let noResultsFoundText = this.props.noResultsFoundText ? this.props.noResultsFoundText : intl.get("WagxAutocomplete.noResult").d("--Nessun Risultato--");
    if (this.props.minChars != null && (this.state.inputValue != null && this.state.inputValue.length < this.props.minChars)) {
      noResultsFoundText = intl.get("WagxAutocomplete.minChars", {minChars:this.props.minChars}).d("--Minimo "+this.props.minChars+" caratteri--");
    }

    const pickerSuggestionsProps = {
      pickerSuggestionsProps: {
        suggestionsHeaderText: this.props.suggestionsHeaderText,
        noResultsFoundText: noResultsFoundText
      }
    }
    const inputProps = {
      ...this.props.inputProps,
      onFocus: this._onFocusHandler.bind(this)
    }

    const renderElement = this.state.loading
      ? <TextField
        placeholder={intl.get("WagxAutocomplete.loading").d("Caricamento in Corso...")}
        disabled={true}
        readOnly={true}
        defaultValue={""}
      />
      : <React.Fragment>
        <TagPicker
          {...pickerSuggestionsProps}
          inputProps={inputProps}
          disabled={this.props.disabled}
          componentRef={this._picker}
          defaultSelectedItems={this.state.selectedItems}
          onBlur={this._onBlurHandler.bind(this)}
          onChange={this._onChangeHandler.bind(this)}
          onInputChange={this._onInputChangeHandler.bind(this)}
          onResolveSuggestions={this._onResolveSuggestionsHandler.bind(this)}
          resolveDelay={this.props.resolveDelay == null ? 500 : this.props.resolveDelay}
          itemLimit={this.props.itemLimit == null ? 1 : this.props.itemLimit}
        />
      </React.Fragment>;
    return (
      <React.Fragment>
        {this.props.onRenderLabel(this.props, null)}
        <div className={className.join(" ")}>
          {renderElement}
        </div>
      </React.Fragment>
    )
  }
}

export default WagxAutocomplete;