import * as actionTypes from "./action-types";

export const showIframeModal = (modalProps) => {
    return {
        type: actionTypes.SHOW_IFRAME_MODAL,
        ...modalProps
    }
}

export const hideIframeModal = (modalProps) => {
  return {
      type: actionTypes.HIDE_IFRAME_MODAL,
      ...modalProps
  }
}