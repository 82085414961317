import { updateObject } from '../utils'
import * as actionTypes from '../actions/action-types';

const initialState = {
  showModal: false,
  curViewId: null,
  mainStyle: null,
  objectId: null,
  loadedObject: null
};

const showSearchModal = (state, action) => {
  return updateObject(state, { showModal: true, curViewId: action.search, mainStyle: action.mainStyle, objectId: action.objectId, loadedObject: action.loadedObject });
}

const hideSearchModal = (state, action) => {
  return updateObject(state, initialState);
}
const logout = (state) => {
  return updateObject(state, initialState);
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGOUT: return logout(state, action);
    case actionTypes.HIDDEN_SEARCH_MODAL: return hideSearchModal(state, action);
    case actionTypes.SHOW_SEARCH_MODAL: return showSearchModal(state, action);

    default:
      return state;
  }
};

export default reducer;