import * as actionTypes from "./action-types";

export const showSearchModal = (modalProps) => {

    return {
        type: actionTypes.SHOW_SEARCH_MODAL,
        ...modalProps
    }
}

export const hideSearchModal = (modalProps) => {

    return {
        type: actionTypes.HIDDEN_SEARCH_MODAL,
        ...modalProps
    }
}
