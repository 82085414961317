/* tabella con form di dettaglio che compare in sostituzione della lista */
export const DATA_TABLE = "DATA_TABLE";
/* tabella con selezione di sottorighe */
export const DATA_TABLE_CHILD_SELECTION = "DATA_TABLE_CHILD_SELECTION";
/* lista con form accanto */
export const DATA_LIST = "DATA_LIST";
/* Lista con figli */
export const NESTED_DATA_LIST = "NESTED_DATA_LIST";
/* Lista con figli che però vengono mostrati tutti */
export const NESTED_DATA_LIST_FULL = "NESTED_DATA_LIST_FULL";
/* Lista formato CARD */
export const DATA_CARD_LIST = "DATA_CARD_LIST";
/* Lista formato CALENDARIO */
export const CALENDAR = "CALENDAR";
/* Lista formato CALENDARIO */
export const GENERIC_FORM = "GENERIC_FORM";
export const REPORT_DOWNLOAD_PROMPT = "REPORT_DOWNLOAD_PROMPT";
export const VIEW_FORM = "VIEW_FORM";
/*Aggregatore di form che deve leggere diverse form per crearne uno unico*/
export const FORM_AGGREGATOR = "FORM_AGGREGATOR";
/*Lista di elementi disposti e raggruppati per "Alberatura di Appartenenza"*/
export const WAGX_MEMBERSHIP_TREE = "MEMBERSHIP_TREE";
/*Selettore di appartenenza visualizzato come 2 data table*/
export const WAGX_MULTI_SELECTOR = "WAGX_MULTI_SELECTOR";
/*Nuova GENERIC_FORM*/
export const WAGX_GENERIC_FORM = "WAGX_GENERIC_FORM";
/*Visualizzazione read-only dei dati*/
export const WAGX_GENERIC_FORM_READ_ONLY = "WAGX_GENERIC_FORM_READ_ONLY";