import { updateObject } from '../utils'
import * as actionTypes from '../actions/action-types';
import { MessageSeverityEnum } from '../../enums/MessageSeverityEnum';
import intl from 'react-intl-universal';
import { getLocalizedProperty } from '../../LocaleUtils';

const initialState = {
  isOpen: false,
  messageSeverityEnum: MessageSeverityEnum.INFO,
  title: null,
  messageList: [],
  permanent: false,
  timeout: 2000,
  onConfirmHandler: null,
  onDismissedHandler: null,
  confirmText: "",
  cancelText: ""
};

const logout = (state, action) => {
  return updateObject(state, initialState);
}

const showModal = (state, action) => {
  const messageSeverityEnum = action.messageSeverityEnum != null ? action.messageSeverityEnum : initialState.messageSeverityEnum;

  return updateObject(state, {
    isOpen: true,
    messageSeverityEnum: messageSeverityEnum,
    title: action?.title != null ? getLocalizedProperty(action, "title", action?.title) : null,
    messageList: Array.isArray(action.messageList) === true ? action.messageList : initialState.messageList,
    permanent: action.permanent === true,
    timeout: action.timeout != null ? action.timeout : initialState.timeout,
    onDismissedHandler: action.onDismissedHandler,
    confirmText: action.confirmText,
    cancelText: action.cancelText
  });
}

const showConfirmModal = (state, action) => {
  const messageSeverityEnum = action.messageSeverityEnum != null ? action.messageSeverityEnum : initialState.messageSeverityEnum;

  return updateObject(state, {
    isOpen: true,
    messageSeverityEnum: messageSeverityEnum,
    title: action?.title != null ? getLocalizedProperty(action, "title", action?.title) : null,
    messageList: Array.isArray(action.messageList) === true ? action.messageList : initialState.messageList,
    permanent: action.permanent === true,
    timeout: action.timeout != null ? action.timeout : initialState.timeout,
    onConfirmHandler: action.onConfirmHandler,
    onDismissedHandler: action.onDismissedHandler,
    confirmText: action.confirmText,
    cancelText: action.cancelText
  });
}

const showModalOnLoginFail = (state, action) => {
  let intlKey = "auth.error.LoginFail.text";
  if (action.error != null && action.error.data != null && action.error.data.error != null) {
    intlKey = action.error.data.error;
  }

  return updateObject(state, {
    isOpen: true,
    messageSeverityEnum: MessageSeverityEnum.ERROR,
    title: null,
    messageList: [intl.get(intlKey).d("Si è verificato un errore in fase di autenticazione")],
    permanent: false,
    timeout: initialState.timeout,
    onDismissedHandler: null
  });
}

const dismissModal = (state, action) => {
  const updatedInitalState = { ...initialState };
  if (action.onDismissedHandler) {
    updatedInitalState.onDismissedHandler = action.onDismissedHandler;
  }
  return updateObject(state, updatedInitalState);
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGOUT: return logout(state, action);
    case actionTypes.SHOW_MODAL: return showModal(state, action);
    case actionTypes.SHOW_CONFIRM_MODAL: return showConfirmModal(state, action);
    case actionTypes.DISMISS_MODAL: return dismissModal(state, action);
    case actionTypes.LOGIN_FAIL: return showModalOnLoginFail(state, action);
    default:
      return state;
  }
};

export default reducer;