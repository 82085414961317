import React, { Component } from 'react'
import { parseString, computeFunctionBody } from '../../functions/utility';
import intl from 'react-intl-universal';
import * as formatters from '../../functions/formatters';
import { getLocalizedProperty } from '../../LocaleUtils';
import { TextField } from 'office-ui-fabric-react';
import { getInternationalizationInfo } from '../../functions/systemInfo';


class WagxCalculatedInput extends Component {

  constructor(props) {
    super(props);

    this.state = {};
    this.ndValue = intl.get("WagxCalculatedInput.N.D.").d("N.D.");
    this.timeout = null;

    if (this.props.useParseString === false) {
      // eslint-disable-next-line
      this.func = new Function("object", "internationalizationInfo", computeFunctionBody(this.props.function));
    }

    this.dependendFieldValues = {};
    this.updateDependendFieldValues();
  }

  componentDidMount() {
    const updatedValue = this.getValue();
    this.onChangeWithTimeout(updatedValue);
  }

  componentDidUpdate(prevProps, prevState) {
    const calculateValue = this.updateDependendFieldValues();

    if (calculateValue) {
      const updatedValue = this.getValue();
      if (prevProps.value !== updatedValue) {
        this.onChangeWithTimeout(updatedValue);
      }
    }
  }

  onChangeWithTimeout = (updatedValue) => {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.props.onChange(null, this.props.field, updatedValue, this.props.formId);
    }, 100);
  }

  updateDependendFieldValues = () => {
    let calculateValue = false;

    if (this.props.dependendFields) {
      for (let i = 0; i < this.props.dependendFields.length; i++) {
        const dependField = this.props.dependendFields[i];
        if (this.dependendFieldValues[dependField] !== this.props.object[dependField]) {
          this.dependendFieldValues[dependField] = this.props.object[dependField];
          calculateValue = true;
        }
      }
    }

    return calculateValue;
  }

  getValue() {
    let value = this.ndValue;

    if (this.props.useParseString === undefined || this.props.useParseString === true) {
      const parsedFunc = parseString(this.props.function, this.dependendFieldValues);
      try {
        // eslint-disable-next-line
        value = eval(parsedFunc);
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        value = this.func(this.dependendFieldValues, getInternationalizationInfo());
      } catch (e) {
        console.error(e);
      }
    }

    return value;
  }

  render() {
    let value = this.props.value;
    if (value == null || value === undefined) {
      value = this.getValue();
    }
    if (this.props.viewFormatter != null || this.props.formatter != null) {
      value = formatters[this.props.viewFormatter != null ? this.props.viewFormatter : this.props.formatter](value);
    }

    return <TextField
      prefix={this.props.prefix}
      suffix={this.props.suffix}
      readOnly={this.props.readOnly == null ? true : this.props.readOnly}
      disabled={this.props.disabled}
      id={"inp-" + this.props.field}
      label={getLocalizedProperty(this.props, 'label')}
      onRenderLabel={this.props.customLabelRender}
      name={this.props.field}
      className={this.props.className}
      value={value}
      multiline={this.props.multiline != null ? this.props.multiline : false}
      placeholder={getLocalizedProperty(this.props, 'placeholder')}
    />;
  }
}

export default WagxCalculatedInput;