import { createTheme } from 'office-ui-fabric-react';

const themes = {
    portal: {
        greyBgTheme: createTheme({
            palette: {
                themePrimary: '#000000',
                themeLighterAlt: '#898989',
                themeLighter: '#737373',
                themeLight: '#595959',
                themeTertiary: '#373737',
                themeSecondary: '#2f2f2f',
                themeDarkAlt: '#252525',
                themeDark: '#151515',
                themeDarker: '#0b0b0b',
                neutralLighterAlt: '#a2ba18',
                neutralLighter: '#9fb718',
                neutralLight: '#99b017',
                neutralQuaternaryAlt: '#8ea415',
                neutralQuaternary: '#889c14',
                neutralTertiaryAlt: '#829613',
                neutralTertiary: '#595959',
                neutralSecondary: '#373737',
                neutralPrimaryAlt: '#2f2f2f',
                neutralPrimary: '#000000',
                neutralDark: '#151515',
                black: '#0b0b0b',
                white: '#a5be19',
            }
        }),
        transparentBgTheme: createTheme({
            palette: {
                themePrimary: '#000000',
                themeLighterAlt: '#898989',
                themeLighter: '#737373',
                themeLight: '#595959',
                themeTertiary: '#373737',
                themeSecondary: '#2f2f2f',
                themeDarkAlt: '#252525',
                themeDark: '#151515',
                themeDarker: '#0b0b0b',
                neutralLighterAlt: '#a2ba18',
                neutralLighter: '#9fb718',
                neutralLight: '#99b017',
                neutralQuaternaryAlt: '#8ea415',
                neutralQuaternary: '#889c14',
                neutralTertiaryAlt: '#829613',
                neutralTertiary: '#595959',
                neutralSecondary: '#373737',
                neutralPrimaryAlt: '#2f2f2f',
                neutralPrimary: '#000000',
                neutralDark: '#151515',
                black: '#0b0b0b',
                white: '#a5be19',
            }
        }),
        darkTheme: createTheme({
            palette: {
                themePrimary: '#000000',
                themeLighterAlt: '#898989',
                themeLighter: '#737373',
                themeLight: '#595959',
                themeTertiary: '#373737',
                themeSecondary: '#2f2f2f',
                themeDarkAlt: '#252525',
                themeDark: '#151515',
                themeDarker: '#0b0b0b',
                neutralLighterAlt: '#a2ba18',
                neutralLighter: '#9fb718',
                neutralLight: '#99b017',
                neutralQuaternaryAlt: '#8ea415',
                neutralQuaternary: '#889c14',
                neutralTertiaryAlt: '#829613',
                neutralTertiary: '#595959',
                neutralSecondary: '#373737',
                neutralPrimaryAlt: '#2f2f2f',
                neutralPrimary: '#000000',
                neutralDark: '#151515',
                black: '#0b0b0b',
                white: '#a5be19',
            }
        }),
        menuBarTheme: createTheme({
            palette: {
                themePrimary: '#000000',
                themeLighterAlt: '#898989',
                themeLighter: '#737373',
                themeLight: '#595959',
                themeTertiary: '#373737',
                themeSecondary: '#2f2f2f',
                themeDarkAlt: '#252525',
                themeDark: '#151515',
                themeDarker: '#0b0b0b',
                neutralLighterAlt: '#a2ba18',
                neutralLighter: '#9fb718',
                neutralLight: '#99b017',
                neutralQuaternaryAlt: '#8ea415',
                neutralQuaternary: '#889c14',
                neutralTertiaryAlt: '#829613',
                neutralTertiary: '#595959',
                neutralSecondary: '#373737',
                neutralPrimaryAlt: '#2f2f2f',
                neutralPrimary: '#000000',
                neutralDark: '#151515',
                black: '#0b0b0b',
                white: '#a5be19',
            }
        }),
        navBarTheme: createTheme({
            palette: {
                themePrimary: '#000000',
                themeLighterAlt: '#898989',
                themeLighter: '#737373',
                themeLight: '#595959',
                themeTertiary: '#373737',
                themeSecondary: '#2f2f2f',
                themeDarkAlt: '#252525',
                themeDark: '#151515',
                themeDarker: '#0b0b0b',
                neutralLighterAlt: '#a2ba18',
                neutralLighter: '#9fb718',
                neutralLight: '#99b017',
                neutralQuaternaryAlt: '#8ea415',
                neutralQuaternary: '#889c14',
                neutralTertiaryAlt: '#829613',
                neutralTertiary: '#595959',
                neutralSecondary: '#373737',
                neutralPrimaryAlt: '#2f2f2f',
                neutralPrimary: '#000000',
                neutralDark: '#151515',
                black: '#0b0b0b',
                white: '#a5be19',
            }
        }),
        userBarTheme: createTheme({
            palette: {
                themePrimary: '#000000',
                themeLighterAlt: '#898989',
                themeLighter: '#737373',
                themeLight: '#595959',
                themeTertiary: '#373737',
                themeSecondary: '#2f2f2f',
                themeDarkAlt: '#252525',
                themeDark: '#151515',
                themeDarker: '#0b0b0b',
                neutralLighterAlt: '#a2ba18',
                neutralLighter: '#9fb718',
                neutralLight: '#99b017',
                neutralQuaternaryAlt: '#8ea415',
                neutralQuaternary: '#889c14',
                neutralTertiaryAlt: '#829613',
                neutralTertiary: '#595959',
                neutralSecondary: '#373737',
                neutralPrimaryAlt: '#2f2f2f',
                neutralPrimary: '#000000',
                neutralDark: '#151515',
                black: '#0b0b0b',
                white: '#a5be19',
            }
        })
    },
    "portal-clever": {
        greyBgTheme: createTheme({
            palette: {
                "themePrimary": "#0078d4",//"#0078d4",
                "themePrimaryAlt": "#eff6fc",//"#0078d4",
                "themeLighter": "#deecf9",
                "themeLight": "#c7e0f4",
                "themeTertiary": "#eeeeee",
                "themeSecondary": "#00376e",
                "themeDarkAlt": "#ffffff",
                "themeDark": "#005a9e",
                "themeDarker": "#004578",
                "neutralLighterAlt": "#ffffff",
                "neutralLighter": "#ffffff", //usato anche per lo sfondo dei pulsanti active
                "neutralLight": "#252525",
                "neutralQuaternaryAlt": "#2f2f2f",
                "neutralQuaternary": "#373737",
                "neutralTertiaryAlt": "#595959",
                "neutralTertiary": "#c8c8c8",
                "neutralSecondary": "#d0d0d0",
                "neutralPrimaryAlt": "#dadada",
                "neutralPrimary": "#000000",
                "neutralDark": "#f4f4f4",
                "black": "#f8f8f8",
                "white": "#cccccc",
                "primaryBackground": "#ffffff",
                "primaryText": "#000000",
                "bodyBackground": "#ffffff",
                "bodyText": "#000000",
                "disabledBackground": "#151515",
                "disabledText": "#595959"
            }
        }),
        transparentBgTheme: createTheme({
            palette: {
                "themePrimary": "transparent",//"#0078d4",
                "themePrimaryAlt": "#eff6fc",//"#0078d4",
                "themeLighter": "#deecf9",
                "themeLight": "#c7e0f4",
                "themeTertiary": "#eeeeee",
                "themeSecondary": "#00376e",
                "themeDarkAlt": "#ffffff",
                "themeDark": "#005a9e",
                "themeDarker": "#004578",
                "neutralLighterAlt": "#ffffff",
                "neutralLighter": "#ffffff", //usato anche per lo sfondo dei pulsanti active
                "neutralLight": "#252525",
                "neutralQuaternaryAlt": "#2f2f2f",
                "neutralQuaternary": "#373737",
                "neutralTertiaryAlt": "#595959",
                "neutralTertiary": "#c8c8c8",
                "neutralSecondary": "#d0d0d0",
                "neutralPrimaryAlt": "#dadada",
                "neutralPrimary": "#000000",
                "neutralDark": "#f4f4f4",
                "black": "#f8f8f8",
                "white": "transparent",
                "primaryBackground": "#ffffff",
                "primaryText": "#000000",
                "bodyBackground": "#ffffff",
                "bodyText": "#000000",
                "disabledBackground": "#151515",
                "disabledText": "#595959"
            }
        }),
        darkTheme: createTheme({
            palette: {
                "themePrimary": "#eeeeee",//"#0078d4",
                "themePrimaryAlt": "#eeeeee",//"#0078d4",
                "themeLighter": "#deecf9",
                "themeLight": "#c7e0f4",
                "themeTertiary": "#eeeeee",
                "themeSecondary": "#2b88d8",
                "themeDarkAlt": "#ffffff",
                "themeDark": "#005a9e",
                "themeDarker": "#004578",
                "neutralLighterAlt": "#0b0b0b",
                "neutralLighter": "#333333", //usato anche per lo sfondo dei pulsanti active
                "neutralLight": "#252525",
                "neutralQuaternaryAlt": "#2f2f2f",
                "neutralQuaternary": "#373737",
                "neutralTertiaryAlt": "#595959",
                "neutralTertiary": "#c8c8c8",
                "neutralSecondary": "#d0d0d0",
                "neutralPrimaryAlt": "#dadada",
                "neutralPrimary": "#ffffff",
                "neutralDark": "#f4f4f4",
                "black": "#f8f8f8",
                "white": "#000000",
                "primaryBackground": "#000000",
                "primaryText": "#ffffff",
                "bodyBackground": "#000000",
                "bodyText": "#ffffff",
                "disabledBackground": "#151515",
                "disabledText": "#595959"
            }
        }),
        menuBarTheme: createTheme({
            palette: {
                themePrimary: 'rgb(146,180,206)',
                themeLighterAlt: 'rgb(146,180,206)',
                themeLighter: 'rgb(146,180,206)',
                themeLight: 'rgb(213,211,41)',
                themeTertiary: 'rgb(146,180,206)',
                themeSecondary: 'white',
                themeDarkAlt: 'rgb(146,180,206)',
                themeDark: 'rgb(146,180,206)',
                themeDarker: 'rgb(146,180,206)',
                neutralLighterAlt: 'rgb(146,180,206)',
                neutralLighter: 'rgb(146,180,206)',
                neutralLight: 'rgb(146,180,206)',
                neutralQuaternaryAlt: 'rgb(146,180,206)',
                neutralQuaternary: 'rgb(146,180,206)',
                neutralTertiaryAlt: 'rgb(146,180,206)',
                neutralTertiary: 'rgb(146,180,206)',
                neutralSecondary: 'rgb(146,180,206)',
                neutralPrimaryAlt: 'rgb(146,180,206)',
                neutralPrimary: 'rgb(146,180,206)',
                neutralDark: 'rgb(146,180,206)',
                black: 'rgb(146,180,206)',
                white: 'rgb(146,180,206)',
            }
        }),
        navBarTheme: createTheme({
            palette: {
                themePrimary: '#ffffff',
                themeLighterAlt: '#767676',
                themeLighter: '#a6a6a6',
                themeLight: '#c8c8c8',
                themeTertiary: '#d0d0d0',
                themeSecondary: '#dadada',
                themeDarkAlt: '#eaeaea',
                themeDark: '#f4f4f4',
                themeDarker: '#f8f8f8',
                neutralLighterAlt: '#3674aa',
                neutralLighter: '#3c78ad',
                neutralLight: '#4680b3',
                neutralQuaternaryAlt: '#4d85b6',
                neutralQuaternary: '#5289b9',
                neutralTertiaryAlt: '#6a9ac5',
                neutralTertiary: '#383939',
                neutralSecondary: 'white',
                neutralPrimaryAlt: '#a3a8a8',
                neutralPrimary: 'white',
                neutralDark: 'white',
                black: '#d8dbdb',
                white: '#00376e',
            }
        }),
        userBarTheme: createTheme({
            palette: {
                themePrimary: '#ffffff',
                themeLighterAlt: '#767676',
                themeLighter: '#a6a6a6',
                themeLight: '#c8c8c8',
                themeTertiary: '#d0d0d0',
                themeSecondary: '#dadada',
                themeDarkAlt: '#eaeaea',
                themeDark: '#f4f4f4',
                themeDarker: '#f8f8f8',
                neutralLighterAlt: '#3674aa',
                neutralLighter: '#3c78ad',
                neutralLight: '#4680b3',
                neutralQuaternaryAlt: '#4d85b6',
                neutralQuaternary: '#5289b9',
                neutralTertiaryAlt: '#6a9ac5',
                neutralTertiary: '#c8c8c8',
                neutralSecondary: '#d0d0d0',
                neutralPrimaryAlt: '#dadada',
                neutralPrimary: '#ffffff',
                neutralDark: '#f4f4f4',
                black: '#f8f8f8',
                white: '#306ea6',
            }
        })
    }
}

export default themes;