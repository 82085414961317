import React, { Component } from 'react';
import { DatePicker as DatePickerFabric } from 'office-ui-fabric-react';
import moment from 'moment-with-locales-es6';
import loadash from 'lodash';
import { computeFunctionBody } from '../../../core/functions/utility';
import './DatePicker.css';
import { getDayPickerStrings } from './DatePickerUtils';

export default class DatePicker extends Component {
  constructor(props) {
    super(props);

    if (this.props.computeDefaultValue) {
      try {
        const funcBody = computeFunctionBody(this.props.computeDefaultValue);
        // eslint-disable-next-line 
        this.computeDefaultValueFunc = new Function("object", funcBody);
      } catch (e) {
        throw new Error(e.message + " DatePicker.computeDefaultValue Probabile errore di sinstassi " + JSON.stringify(this.props));
      }
    }

    if (this.props.computeMinDate) {
      try {
        const funcBody = computeFunctionBody(this.props.computeMinDate);
        // eslint-disable-next-line 
        this.computeMinDateFunc = new Function("object", funcBody);
      } catch (e) {
        throw new Error(e.message + " DatePicker.computeMinDate Probabile errore di sinstassi " + JSON.stringify(this.props));
      }
    }

    if (this.props.computeMaxDate) {
      try {
        const funcBody = computeFunctionBody(this.props.computeMaxDate);
        // eslint-disable-next-line 
        this.computeMaxDateFunc = new Function("object", funcBody);
      } catch (e) {
        throw new Error(e.message + " DatePicker.computeMaxDate Probabile errore di sinstassi " + JSON.stringify(this.props));
      }
    }

    this.state = {
      defaultValue: this.computeDefaultValue()
    };

  }

  computeDefaultValue = () => {
    if (this.computeDefaultValueFunc) {
      const res = this.computeDefaultValueFunc(this.getObject());
      if (moment.isDate(res)) {
        return moment(res);
      } else if (typeof res == "number") {
        return moment().add(res, "day");
      }
    }
    return null;
  }

  onSelectDateHandler = (date) => {
    this.evaluateDate(date);
  }

  getObject = () => {
    return this.props.getObject != null ? this.props.getObject() : this.props.object;
  }

  stripTime = (date) => {
    if (date != null && this.props.stripTime === true) {
      date = moment(date).startOf('day').toDate();
    }
    return date;
  }

  parseMinDate = () => {
    if (this.computeMinDateFunc) {
      const res = this.computeMinDateFunc(this.getObject());
      if (moment.isDate(res)) {
        return this.stripTime(res);
      }
    }

    if (this.props.minDateField != null) {

      const dateField = loadash.get(this.getObject(), this.props.minDateField, null);
      if (dateField != null && !isNaN(+dateField)) {
        return this.stripTime(new Date(+dateField));
      }
    }
    if (this.props.minDate != null) {
      if (this.props.minDate instanceof Date) {
        return this.stripTime(this.props.minDate);
      } else {
        let d = new Date();
        switch (this.props.minDate) {
          case "TODAY":
            //GIUSTO SICCOME DEVE TORNARE new Date()
            break;
          default:
            if (!isNaN(+ this.props.minDate)) {
              d.setDate(d.getDate() + (+this.props.minDate));
            }
            break;
        }
        return this.stripTime(d);
      }
    }
    return null;
  }

  parseMaxDate = () => {
    if (this.computeMaxDateFunc) {
      const res = this.computeMaxDateFunc(this.getObject());
      if (moment.isDate(res)) {
        return this.stripTime(res);
      }
    }

    if (this.props.maxDateField != null) {
      const dateField = loadash.get(this.getObject(), this.props.maxDateField, null);
      if (dateField != null && !isNaN(+dateField)) {
        return this.stripTime(new Date(+dateField));
      }
    }
    if (this.props.maxDate) {
      if (this.props.maxDate instanceof Date) {
        return this.stripTime(this.props.maxDate);
      } else {
        switch (this.props.maxDate) {
          case "TODAY":
            return this.stripTime(new Date());
          default:
            break;
        }
      }
    }
    return null;
  }

  componentDidMount() {
    let date = this.props.value != null && this.props.value !== "" ? this.props.value : null;

    if (date == null) {
      if (this.state.defaultValue != null) {
        date = this.state.defaultValue;
      }
    }

    if (this.props.fireOnChangeOnDidMount) {
      this.evaluateDate(date);
    }
  }

  componentDidUpdate = (previousProps, previousState) => {
    let date = this.props.value != null && this.props.value !== "" ? this.props.value : null;
    const defaultValue = this.computeDefaultValue();

    if (previousState.defaultValue != null && !previousState.defaultValue.isSame(defaultValue, 'day')) {
      // valore di default precedente esiste ed è diverso da quello attuale, o quello attuale è null
      this.setState({ defaultValue: defaultValue }, () => {
        if (date == null || previousState.defaultValue.isSame(date, 'day')) {
          this.evaluateDate(defaultValue);
        }
      });
    } else if (defaultValue != null && !defaultValue.isSame(previousState.defaultValue, 'day')) {
      // valore di default precedente è null e valore di default attuale è valorizzato
      this.setState({ defaultValue: defaultValue }, () => {
        if (date == null) {
          this.evaluateDate(defaultValue);
        }
      });
    }

    if (this.props.fireOnChangeOnDidMount && (previousProps.disabled !== this.props.disabled)) {
      this.evaluateDate(date);
    }
  }

  evaluateDate = (date) => {
    let mDate = null;
    if (date) {
      mDate = moment(date);
      //per risolvere problemi con ora legale su IE
      //Si verificava sul cambio di data nascita nella modifica dati anagrafici
      // if (mDate.isDST()) {
      //     mDate.add(1, 'h');
      // }
    }
    if (this.props.stripTime === true && mDate != null) {
      mDate = mDate.startOf('day');
    }
    const dt = mDate != null ? this.dateInRange(mDate) : null;
    this.props.onChange(undefined, this.props.field, dt , null, this.props.formId);
  }

  dateInRange = (dateToCheck) => {
    let minDate = this.parseMinDate();
    let maxDate = this.parseMaxDate();

    if (this.props.getValueAsIs === true) {
      return dateToCheck.valueOf();
    } else if (minDate != null && dateToCheck.valueOf() < minDate.getTime()) {
      return minDate.getTime();
    } else if (maxDate != null && dateToCheck.valueOf() > maxDate.getTime()) {
      return maxDate.getTime();
    } else {
      return dateToCheck.valueOf();
    }
  }

  _handleChange = (e) => {
    e.preventDefault();
  };

  render() {
    const ld = moment.localeData();
    const dayPickerStrings = getDayPickerStrings(ld);

    const placeHolderClass = this.props.value && this.props.value !== "" ? "" : "PlaceHolder";
    const className = ["DatePickerContainer"];
    let formFeedBack = null;

    if (this.props.className != null) {
      className.push(this.props.className);
    }
    if (this.props.valid === false || (this.props.formFeedBack && this.props.formFeedBack !== "")) {
      className.push("invalidBox");
      formFeedBack = <p style={{ color: "rgb(168, 0, 0)" }}>{this.props.formFeedBack}</p>;
    }

    return (
      <div className={className.join(" ")}>
        {this.props.children}
        <DatePickerFabric
          label={this.props.label}
          textField={{ onRenderLabel: this.props.onRenderLabel }}
          placeholder={this.props.placeholder}
          isRequired={this.props.required && !this.props.disabled}
          disabled={this.props.disabled}
          allowTextInput={this.props.allowTextInput}
          ariaLabel={this.props.label}
          strings={dayPickerStrings}
          value={this.props.value}
          className={placeHolderClass}
          onSelectDate={this.onSelectDateHandler}
          formatDate={this._onFormatDate}
          disableAutoFocus={false}
          firstDayOfWeek={ld.firstDayOfWeek()}
          minDate={this.parseMinDate()}
          maxDate={this.parseMaxDate()}
          parseDateFromString={this._parseDateFromStringHandler}
          invalidInputErrorMessage={this.props.formFeedBack}
          // onBlur={this.onBlurHandler}
          onPaste={this._handleChange}
        />
        {formFeedBack}
      </div>
    );
  }
  _parseDateFromStringHandler = (dateStr) => {
    const formatiValidi = [
      { regexp: /^[0-3][0-9]\/[0-1][0-9]\/[0-9]{4}$/g, format: "DD/MM/YYYY" },
      { regexp: /^[0-3][0-9]\/[0-1][0-9]\/[0-9]{2}$/g, format: "DD/MM/YY" },

      { regexp: /^[1-9]\/[1-9]\/[0-9]{4}$/g, format: "D/M/YYYY" },
      { regexp: /^[1-9]\/[1-9]\/[0-9]{2}$/g, format: "D/M/YY" },

      { regexp: /^[1-9]\/[0-1][0-9]\/[0-9]{4}$/g, format: "D/MM/YYYY" },
      { regexp: /^[1-9]\/[0-1][0-9]\/[0-9]{2}$/g, format: "D/MM/YY" },

      { regexp: /^[0-3][0-9]\/[0-1][0-9]\/[0-9]{4}$/g, format: "DD/M/YYYY" },
      { regexp: /^[0-3][0-9]\/[0-1][0-9]\/[0-9]{2}$/g, format: "DD/M/YY" },

      { regexp: /^[0-9]{8}$/g, format: "DDMMYYYY" },
      { regexp: /^[0-9]{6}$/g, format: "DDMMYY" }

    ];
    let result = null;
    formatiValidi.forEach((item) => {
      if (item.regexp.test(dateStr)) {
        const m = moment(dateStr, item.format);
        result = m.toDate();
        return true;
      }
    });
    return result;
  }

  _onFormatDate = (date) => {
    if (date == null) {
      return "";
    }
    date = new Date(date);
    return moment(date).format("L");
  };
}