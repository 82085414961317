import { icons } from './icons';
import * as formatters from './formatters';
import _ from 'lodash';
import { getInternationalizationInfo } from '../functions/systemInfo';
import { store } from '../../store-config';

export const parseString = (string, object, formatterFieldMap, optionalObject) => {
  if (string == null) {
    return "";
  }
  if (typeof string !== "string" || string.indexOf("{") === -1) {
    return string;
  }

  const stringFields = string.match(/(\{)(.*?)(\})/g);
  let parsedString = string;
  if (stringFields) {
    stringFields.forEach(stringField => {
      let fieldName = stringField.substring(1, stringField.length - 1);
      try {
        if (_.has(optionalObject, fieldName) === true) {
          if (formatterFieldMap && formatterFieldMap[fieldName]) {
            parsedString = parsedString.replace(stringField, formatters[formatterFieldMap[fieldName]](_.get(optionalObject, fieldName, "")));
          } else {
            parsedString = parsedString.replace(stringField, _.get(optionalObject, fieldName, ""));
          }
        } else if (!_.has(object, fieldName) || _.get(object, fieldName, "") === null) {
          parsedString = parsedString.replace(stringField, "");
        } else {
          if (formatterFieldMap && formatterFieldMap[fieldName]) {
            parsedString = parsedString.replace(stringField, formatters[formatterFieldMap[fieldName]](_.get(object, fieldName, "")));
          } else {
            parsedString = parsedString.replace(stringField, _.get(object, fieldName, ""));
          }

        }
      } catch (exception) {
        console.warn(exception);
      }
    });
  }

  return parsedString;
}

export const parseStringFromApplyFilters = (string, applyFilters, replaceWithNull = true) => {
  const stringFields = string.match(/(\{)(.*?)(\})/g);
  let parsedString = string;
  if (stringFields && applyFilters) {
    stringFields.forEach(stringField => {
      let fieldName = stringField.substring(1, stringField.length - 1);
      try {
        // eslint-disable-next-line 
        applyFilters.forEach(object => {
          if (object.param === fieldName) {
            parsedString = parsedString.replace(stringField, object.value);
          }
        });
      } catch (exception) {
        console.warn(exception);
      }
    });

    parsedString = replaceWithNull ? cleanupParseString(stringFields, parsedString, "") : parsedString;
  }
  return parsedString;
}

export const parseConditionString = (string, object, formatterFieldMap, replaceWithNull = true) => {
  if (string) {
    const stringFields = string.match(/(\{)(.*?)(\})/g); // match tutte le stringhe che sono circondate da {} (es: {xyzqw#} )
    let parsedString = string;
    if (stringFields) {
      stringFields.forEach(stringField => {
        let fieldName = stringField.substring(1, stringField.length - 1);
        try {
          if (!_.has(object, fieldName)) {
            parsedString = replaceWithNull ? parsedString.replace(stringField, "null") : parsedString;
          } else {
            if (formatterFieldMap && formatterFieldMap[fieldName]) {
              let valueToReplace = formatters[formatterFieldMap[fieldName]](_.get(object, fieldName, "null"));
              if (("" + valueToReplace).indexOf("'") !== -1) {
                valueToReplace = valueToReplace.replace(/'/g, '\\\'');
              }
              parsedString = parsedString.replace(stringField, valueToReplace);
            } else {
              let valueToReplace = _.get(object, fieldName, "null");
              if (("" + valueToReplace).indexOf("'") !== -1) {
                valueToReplace = valueToReplace.replace(/'/g, '\\\'');
              }
              parsedString = parsedString.replace(stringField, valueToReplace);
            }
          }
        } catch (exception) {
          console.warn(exception);
        }
      });
    }

    const stringInternalDatas = string.match(/(#)(.*?)(#)/g);   // match tutte le stringhe che sono circondate da # (es: #xyzqw#)
    if (stringInternalDatas) {  // se ho matchato almento un valore del tipo #xyzqw#
      stringInternalDatas.forEach((stringInternalData) => { // scorro tutte le stringhe matchate
        if (stringInternalData === "#profiles#") { // se la stringa matchata e' '#profiles#'
          const profiles = store.getState().auth.appState.profiles;   // recupero i profili dallo store di Redux
          if (profiles) {
            const array = JSON.stringify(profiles);
            parsedString = parsedString.replace(stringInternalData, array); // sostituisco stringa #profile# con l'array dei profili
          } else {
            parsedString = parsedString.replace(stringInternalData, "");
          }
        } else if (stringInternalData != null && stringInternalData.indexOf("internationalizationInfo.") !== -1) {
          // se ho trovato #internationalizationInfo.xyz#
          const internationalizationInfo = getInternationalizationInfo();

          // recupero l'attributo xyz e lo sostituisco nella stringa
          const attribute = stringInternalData.replace("#", "").replace("internationalizationInfo.", "");
          const value = JSON.stringify(internationalizationInfo[attribute]);

          parsedString = parsedString.replace(stringInternalData, value);
        }
      });
    }
    return parsedString;
  } else {
    return "";
  }
}

export const parseConditionStringFromApplyFilters = (string, applyFilters) => {
  const stringFields = string.match(/(\{)(.*?)(\})/g);
  let parsedString = string;
  if (stringFields) {
    stringFields.forEach(stringField => {
      let fieldName = stringField.substring(1, stringField.length - 1);
      try {
        // eslint-disable-next-line 
        applyFilters.forEach(object => {
          if (object.param === fieldName) {
            let valueToReplace = object.value;
            valueToReplace = ("" + valueToReplace).replace(/'/g, '\\\'');
            parsedString = parsedString.replace(stringField, valueToReplace);
          }
        });
      } catch (exception) {
        console.warn(exception, applyFilters);
      }
    });
    parsedString = cleanupParseString(stringFields, parsedString, "null");
  }
  return parsedString;
}

export const textContains = (search, text, caseSensitive) => {

  if (!caseSensitive) {
    text = text.toLowerCase();
    search = search.toLowerCase();
  }
  return text.indexOf(search) !== -1;
}

export const findIconName = (value) => {
  let returnValue = null;
  if (typeof value === "string" && value !== "") {
    returnValue = value;
    if (icons[value]) {
      returnValue = icons[value];
    }
  }
  return returnValue;

}

const cleanupParseString = (stringFields, parsedString, cleanValue) => {
  // Clean up
  stringFields.forEach(stringField => {
    try {
      parsedString = parsedString.replace(stringField, cleanValue);
    } catch (exception) {
      console.warn(exception);
    }
  });
  return parsedString;
}

export const isEmpty = (value) => {
  return value != null && ("" + value).trim() === "";
}

export const isNotEmpty = (value) => {
  return !isEmpty(value);
}

export const isNullOrEmpty = (value) => {
  return value == null || ("" + value).trim() === "";
}

export const isNotNullOrEmpty = (value) => {
  return !isNullOrEmpty(value);
}

export const stripHtml = (value, replaceStr = ' ') => {
  if (isNullOrEmpty(value)) {
    return "";
  }
  return (""+value).replace(/(<([^>]+)>)/ig, replaceStr);
}

/*
  FUNZIONE CHE TRASFORMA UNA VARIABILE IN STRINGA
  INPUT:
    - value: la variabile da trasformare
    - valueIfNull: il valore da restituire se la variabile è null
  OUTPUT:
    (string)
*/
export const toStringSafe = (value, valueIfNull) => {

  let defaultValue = "0";

  if (valueIfNull != null) {
    defaultValue = valueIfNull;
  }

  if (isNullOrEmpty(value)) {
    return defaultValue;
  }

  return value.toString();
}
