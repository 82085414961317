import React, { lazy, Suspense } from 'react';
import intl from 'react-intl-universal';
import * as formatters from "../../../functions/formatters";
import * as DataTableTdTypes from './DataTableTdTypes';
import WagxLink from '../../WagxLink/WagxLink';
import * as classes from './DataTableTd.css';
import { Icon, IconButton, Label } from 'office-ui-fabric-react';
import * as actions from "../../../store/actions";
import { connect } from 'react-redux';
import { parseString, computeFunctionBody, parseConditionString, parseConditionStringFromApplyFilters, findIconName, isNotNullOrEmpty, retrieveCalculatedValue, computeCalculatedValueAdditionalObject } from '../../../functions/utility';
import './DataTableTd.css';
import { getLocalizedProperty } from '../../../LocaleUtils';
import IconSwitchComponent from '../../IconSwitchComponent/IconSwitchComponent';
import WagxIcon from '../../WagxIcon/WagxIcon';
import TextWithBorder from '../../TextWithBorder/TextWithBorder';
import GenericInput from '../../GenericInput/GenericInput';
import lodash from 'lodash';
import Spinner from '../../../UI/Spinner/Spinner';
import { isNullOrEmpty } from '../../../functions/StringUtils';

/**
 * @class DataTableTd Stateless Component
 * @classdesc Classe che disegna un <td/> in tabella
 * @param fieldValue
 * @param fieldType
 * @param formatter
 * @param onClick handler
 * @param destinationTitle if link type
 * @param object if link type
 * @param filters if link type
 * @param path if link type
 * @see WagxLink
 */
const dataTableTd = (props) => {
  if (props.fieldType === "hidden") {
    return null;
  }

  const { object } = props;

  const additionalObjectResult = computeCalculatedValueAdditionalObject(props.calculateValueFunctionParameters, props);
  let fieldValue = retrieveCalculatedValue(props.calculateValueFunction, object, props.field, additionalObjectResult.name, additionalObjectResult.object);
  let title = null;

  if (props.columnStructure != null) {
    if (props.columnStructure.title != null) {
      title = getLocalizedProperty(props.columnStructure, "title", props.title, object);
    } else if (props.columnStructure.titleCalculateValueFunction != null) {
      const titleAdditionalObjectResult = computeCalculatedValueAdditionalObject(props.titleCalculateValueFunctionParameters, props.columnStructure);
      title = retrieveCalculatedValue(props.columnStructure.titleCalculateValueFunction, object, titleAdditionalObjectResult.name, titleAdditionalObjectResult.object);
    }
  }

  if (props.titleFormatter != null && title != null) {
    try {
      if (props.titleFormatterParameters) {
        title = formatters[props.titleFormatter](title, props.titleFormatterParameters);
      } else {
        title = formatters[props.titleFormatter](title);
      }
    } catch (e) {
      console.error("Formatter [" + props.titleFormatter + "] does not exist!", e);
    }
  }

  let onClick = null;
  let content = null;
  let tdClasses = [classes.DataTableTd];
  let showColumn = true;
  if (props.showIf) {
    let parsedString = props.showIf;
    if (object) {
      try {
        parsedString = parseConditionString(parsedString, object, null, false);
        // eslint-disable-next-line 
        showColumn = eval(parsedString);
      } catch (e) {
        showColumn = true;
      }
    }
    if (props.applyFilters) {
      try {
        parsedString = parseConditionStringFromApplyFilters(parsedString, props.applyFilters);
        // eslint-disable-next-line 
        showColumn = eval(parsedString);
      } catch (e) {
        showColumn = true;
      }
    }
  }
  if (showColumn) {
    if (fieldValue != null) {
      let disabled = false;
      if (props.enableIf) {
        try {
          const stringToEval = parseConditionString(props.enableIf, object);
          // eslint-disable-next-line
          disabled = !eval(stringToEval);
        } catch (e) {
          console.warn(e);
        }
      }
      switch (props.fieldType) {
        case DataTableTdTypes.DETAIL:
          tdClasses.push(classes.detail);
          onClick = () => props.showDetailHandler(fieldValue);
          content = (
            <Icon iconName={'EditSolid12'} className="DataTableTdIconClickable" />
          );
          break;
        case DataTableTdTypes.LINK:
          content = (
            <WagxLink {...props} />
          );
          break;
        case DataTableTdTypes.HTML:
          if (props.formatter) {
            try {
              if (props.formatterParameters) {
                fieldValue = formatters[props.formatter](fieldValue, props.formatterParameters, object);
              } else {
                fieldValue = formatters[props.formatter](fieldValue, null, object);
              }
            } catch (e) {
              console.error("Formatter [" + props.formatter + "] does not exist!", e);
            }
          }

          content = <div dangerouslySetInnerHTML={{ __html: fieldValue }} />
          break;
        case DataTableTdTypes.ICON_BUTTON:
          content = <IconButton onClick={props.onIconButtonClick} style={props.style} iconProps={{ style: props.style, iconName: props.icon ? props.icon : props.iconName }} />;
          break;
        case DataTableTdTypes.ICON:
          let iconName = "";
          const text = getLocalizedProperty(props, "text", null, object);
          const className = parseString(props.className, object);
          const iconFamily = props.iconFamily;
          if (text !== "") {
            iconName = findIconName(text);
          } else if (props.icon) {
            iconName = props.icon
          } else {
            iconName = findIconName(fieldValue);
          }
          let tooltip = getLocalizedProperty(props, "tooltip", null, object);
          if (tooltip == null || tooltip === "") {
            tooltip = getLocalizedProperty(props.columnStructure, "title", null, object);
          }
          let tooltipArray = tooltip.match(/[{}]/g);
          if (props.title) {
            tooltip = getLocalizedProperty(props.columnStructure, "title", null, object);
          } else if (tooltip === "") {
            tooltip = null;
          } else if (tooltipArray && tooltipArray.length > 0) {
            tooltip = getLocalizedProperty(props, "tooltip", null, object);
          }
          if (iconName != null) {
            if (iconFamily) {
              content = <WagxIcon iconName={iconName} iconFamily={iconFamily} title={tooltip} className={className} />;
            } else {
              content = <Icon iconName={iconName} title={tooltip} className={className} />;
            }
          }
          break;
        case DataTableTdTypes.ITEMS_COUNT:
          content = props.text;
          break;
        case DataTableTdTypes.FILE_DOWNLOAD:
          const downloadPath = parseString(props.downloadPath, object);
          content = <IconButton {...props.iconButtonProps} onClick={() => {
            props.downloadFileHandler(props.viewId, props.downloadPath == null ? fieldValue : downloadPath, object, props.pageViewToReload)
          }} style={props.style} iconProps={{ style: props.style, iconName: props.iconName }} />
          break;
        case DataTableTdTypes.OPEN_VIEW:
          content = (
            <IconButton
              {...props.iconButtonProps}
              disabled={disabled}
              title={getLocalizedProperty(props.columnStructure, "title", null, object)}
              onClick={() => props.openViewHandler(props, props.actionProps)}
              style={props.style}
              iconProps={{ style: props.style, iconName: props.icon ? props.icon : props.iconName }}
            />
          )
          break;
        case DataTableTdTypes.SEND_DATA:
          title = getLocalizedProperty(props.columnStructure, "title", null, object);
          const sendDataClassName = ["wagx-send-data"];
          if (isNotNullOrEmpty(props.className)) {
            sendDataClassName.push(props.className);
          }
          content = <IconButton className={sendDataClassName.join(" ")} {...props.iconButtonProps} disabled={disabled} title={title} onClick={() => {
            const currentAction = {
              functionName: props.functionName,
              confirmMessage: props.confirmMessage,
              preAction: props.preAction,
              returnsFile: props.returnsFile,
              handlingMsgError: props.handlingMsgError
            };
            if (props.asyncTask === true) {
              currentAction.asyncTask = props.asyncTask;
              currentAction.returnsFile = props.returnsFile;
              currentAction.asyncTaskFunctionName = props.asyncTaskFunctionName;
              currentAction.asyncTasktCheckTimeout = props.asyncTasktCheckTimeout;
            }
            if (props.confirmMessage != null) {
              currentAction.actionProps = {
                updatePageOnSuccess: props.updatePageOnSuccess,
                updatePivotOnSuccess: props.updatePivotOnSuccess,
                updateComponentOnSuccess: props.updateComponentOnSuccess,
                keepRouterState: props.keepRouterState,
                navigateOnSuccess: props.navigateOnSuccess,
                loadData: props.loadData !== false
              };
              props.sendDataConfirmDialog(currentAction, object);
            } else {
              if (currentAction.preAction) {
                props.sendDataPreActionDialog(currentAction, object);
              }
              currentAction.actionProps = {
                updatePageOnSuccess: props.updatePageOnSuccess,
                updatePivotOnSuccess: props.updatePivotOnSuccess,
                doInBackground: props.doInBackground,
                loadData: props.loadData !== false
              }
              props.sendData(currentAction, object);
            }
          }} style={props.style} iconProps={{ style: props.style, iconName: props.icon ? props.icon : props.iconName }} />
          break;
        case DataTableTdTypes.CONDITIONAL:
          for (let i = 0; i < props.conditions.length; i++) {
            const condition = props.conditions[i];
            let currentValue;
            if (object[condition.key] !== undefined) {
              currentValue = object[condition.key];
            } else {
              const filter = props.applyFilters?.find((item) => condition.key === item.param);
              currentValue = filter ? filter.value : null;
            }
            const operator = condition.operator ? condition.operator : "";

            if (condition.conditionFunction != null) {
              const funcBody = computeFunctionBody(condition.conditionFunction);
              // eslint-disable-next-line
              const func = new Function("object", funcBody);
              condition.value = func(object);
            }


            let objValue;
            if (typeof condition.value === "boolean") {
              objValue = condition.value;
            } else if (Array.isArray(condition.value)) {
              objValue = condition.value.filter((element) => element === currentValue)[0];
            } else if (typeof condition.value === "number") {
              objValue = condition.value;
            } else {
              objValue = condition.value ? parseString(condition.value, object) : null;
            }

            switch (operator.toUpperCase()) {
              case "NOT EQ":
                // eslint-disable-next-line
                if (currentValue != objValue) {
                  return dataTableTd({ ...props, conditions: undefined, ...condition.genericInputProps, object: object, columnStructure: condition.genericInputProps });
                }
                break;
              case "GT"://greater then
                if (currentValue > objValue) {
                  return dataTableTd({ ...props, conditions: undefined, ...condition.genericInputProps, object: object, columnStructure: condition.genericInputProps });
                }
                break;
              case "LT"://less then
                if (currentValue < objValue) {
                  return dataTableTd({ ...props, conditions: undefined, ...condition.genericInputProps, object: object, columnStructure: condition.genericInputProps });
                }
                break;
              default:
                // eslint-disable-next-line eqeqeq
                if (currentValue == objValue) {
                  return dataTableTd({ ...props, conditions: undefined, ...condition.genericInputProps, object: object, columnStructure: condition.genericInputProps });
                }
            }
          }
          break;
        case DataTableTdTypes.CHOOSE:
          for (let i = 0; i < props.conditions.length; i++) {
            const condition = props.conditions[i];
            const parsedCondition = condition.check ? parseString(condition.check, object) : 'false';
            // eslint-disable-next-line 
            const res = new Function('return ' + parsedCondition)();
            if (res === true) {
              return dataTableTd({ ...props, conditions: undefined, ...condition.props, object: object });
            }
          }
          break;
        case DataTableTdTypes.ICON_SWITCH:
          //Questo tipo servirà per indicare se una riga è specificata come preferita o meno
          content = <IconSwitchComponent {...props} disabled={disabled} />
          break;
        case DataTableTdTypes.TEXT_BORDER:
          title = getLocalizedProperty(props.columnStructure, "title", null, object);
          var textWithBorder = getLocalizedProperty(props, "text", null, object);
          content = <TextWithBorder {...props} title={title} text={textWithBorder}></TextWithBorder>
          break;
        case DataTableTdTypes.GENERIC_INPUT:
          let lovValue;
          if (props.lovValueFieldName != null) {
            lovValue = props.object[props.lovValueFieldName];
          }
          content = <GenericInput {...props} lovValue={lovValue} disabled={disabled} avoidContainer={true} />
          break;
        case DataTableTdTypes.ENGINE_WORKFLOW:
          content = <IconButton
            // {...props.iconButtonProps}
            disabled={disabled}
            onClick={() => props.prepareEngineWorkflow(props.actionProps, object)}
            style={props.style}
            iconProps={{ style: props.style, iconName: props.actionProps.icon }}
          />;
          break;
        case DataTableTdTypes.CUSTOM:
          content = <IconButton
            disabled={disabled}
            onClick={() => props[props.functionName](props, object)}
            style={props.style}
            iconProps={{ ...props.iconProps }}
          />
          break;
        case DataTableTdTypes.DYNAMIC:
          let DynamicInputComponent = lazy(() => import("../../../../" + props.componentPath));

          let label = null;
          if (props.props != null && props.props.label != null) {
            //      label = <Label>{intl.get(this.props.props.label).d(this.props.props.label)}</Label>
            label = <Label>{getLocalizedProperty(props.props, 'label', null, object)}</Label>
          }

          content = (
            <Suspense fallback={<Spinner label={intl.get("DynamicInput.loading").d("Caricamento in corso...")} />}>
              <div className={props.className}>
                {label}
                <DynamicInputComponent
                  {...props}
                  value={fieldValue}
                  getObject={() => { return object }}
                />
              </div>
            </Suspense>
          )
          break;
        default:
          let textByProps = getLocalizedProperty(props, 'text', null, object, props.formatterFieldMap);
          if (isNotNullOrEmpty(textByProps) === true) {
            if (props.evalParsedString) {
              try {
                // eslint-disable-next-line
                textByProps = eval(textByProps);
              } catch (e) {

              }
            }
            if (props.formatter) {
              try {
                if (props.formatterParameters) {
                  textByProps = formatters[props.formatter](textByProps, props.formatterParameters, object);
                } else {
                  textByProps = formatters[props.formatter](textByProps, null, object);
                }
              } catch (e) {
                console.error("Formatter [" + props.formatter + "] does not exist!", e);
              }
            }

            if (props.valueAsTooltip) {
              // Se il tooltip deve avere lo stesso valore del campo allora sovrascrivo title, sotto verrà utilizzata
              title = textByProps;
            }
            // Rimossa title dallo span perché gestito sotto globale
            content = (<span>{textByProps}</span>);
          } else {
            if (Array.isArray(fieldValue)) {
              const valueArray = fieldValue.map((element) => {
                let value = null;
                switch (typeof element) {
                  case "object":
                    if (element.messageKey != null) {
                      value = intl.get(element.messageKey, element.parameters).d(element.messageKey);
                    }
                    if (props.formatter) {
                      value = formatters[props.formatter](value, props.formatterParameters, object);
                    }
                    break;
                  default:
                    if (props.formatter) {
                      value = formatters[props.formatter](value, props.formatterParameters, object);
                    }
                }
                return value;
              });

              content = valueArray.join(". ");
              if (props.title != null) {
                title = valueArray.join(". ");
              }
            } else {
              if (props.formatter) {
                try {
                  if (props.formatterParameters) {
                    fieldValue = formatters[props.formatter](fieldValue, props.formatterParameters, object);
                  } else {
                    fieldValue = formatters[props.formatter](fieldValue, null, object);
                  }
                } catch (e) {
                  console.error("Formatter [" + props.formatter + "] does not exist!", e);
                }
              }
              content = "";
              if (isNotNullOrEmpty(fieldValue)) {
                content = fieldValue;
              } else if (isNullOrEmpty(content) && props.placeholder != null) {
                content = getLocalizedProperty(props.columnStructure, 'placeholder', props.placeholder, object);
              }

              if (props.valueAsTooltip) {
                // Se il tooltip deve avere lo stesso valore del campo allora sovrascrivo title, sotto verrà utilizzata
                title = fieldValue;
              }
            }
          }
          break;
      }
    } else {
      switch (props.fieldType) {
        case DataTableTdTypes.ICON:
          content = null;
          break;
        default:
          let text = getLocalizedProperty(props, "text", null, object);

          if (props.evalParsedString) {
            // eslint-disable-next-line
            text = eval(text);
          }
          if (props.formatter) {
            if (props.formatterParameters) {
              text = formatters[props.formatter](text, props.formatterParameters);
            } else {
              text = formatters[props.formatter](text);
            }
          }
          content = (<span>{text}</span>);
          break;
      }
    }
  } else {
    // Caso particolare per il link che deve essere visibile ma non cliccabile nel caso di showIf valutato a false. (gli 0 nello scadenzario lo usano)
    // Sarebbe meglio aggiungere un altro parametro es enableLinkIf 
    if (props.fieldType === DataTableTdTypes.LINK) {
      const text = getLocalizedProperty(props, "text", null, object);
      if (props.formatter) {
        if (props.formatterParameters) {
          fieldValue = formatters[props.formatter](text, props.formatterParameters);
        } else {
          fieldValue = formatters[props.formatter](text);
        }
      }
      content = (<span>{text}</span>);
    }
  }

  let tooltip = title;
  if (props.showTooltip) {
    tooltip = getLocalizedProperty(props, "tooltip");
    if (tooltip == null || tooltip === "") {
      tooltip = getLocalizedProperty(props.columnStructure, "title");
    }
    const tooltipArray = tooltip.match(/[{}]/g);
    if (tooltip === "") {
      tooltip = null;
    } else if (tooltipArray && tooltipArray.length > 0) {
      tooltip = parseString(tooltip, object);
    }
  }
  tooltip = lodash.unescape(tooltip);
  tooltip = tooltip != null ? formatters["removeHtmlStringFormatter"](tooltip) : tooltip;

  return (
    <div className={tdClasses.join(" ") + " DataTableTd"} style={props.style} onClick={onClick} title={tooltip}>{content}</div>
  );
};

const mapStateToProps = state => {
  return {

  };
}
const mapDispatchToProps = dispatch => {
  return {
    showSearchModal: (search, mainStyle, objectId, loadedObject) => dispatch(actions.showSearchModal({ search: search, mainStyle: mainStyle, objectId: objectId, loadedObject: loadedObject })),
    setPageState: (state) => dispatch(actions.setPageState({ state: state }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(dataTableTd);