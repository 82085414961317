import { Icon, Stack } from 'office-ui-fabric-react';
import React from 'react';
import './ErrorLabel.css';

export const ErrorLabel = (props) => {
  const label = props.label;
  const stackTokens = { childrenGap: 4, maxWidth: 300 };
  const errorIconProps = props.iconProps != null ? props.iconProps : { iconName: 'Error', style: { color: "rgb(168,0,0)", fontSize: "14px", cursor: "pointer" } };
  const warnigIconProps = props.iconProps != null ? props.iconProps : { iconName: 'Warning', style: { color: "rgb(255, 102, 0)", fontSize: "14px", paddingRight: "5px", cursor: "pointer" } };
  let iconSpan = null;
  if (props.errorMessage != null && props.errorMessage !== "") {
    iconSpan = (
      <Icon
        {...errorIconProps}
        title={props.errorMessage}
        ariaLabel={props.errorMessage}
      />
    );
  } else if (props.warningMessage != null && props.warningMessage !== "") {
    iconSpan = (
      <Icon
        {...warnigIconProps}
        title={props.warningMessage}
        ariaLabel={props.warningMessage}
      />
    );
  }
  return (
    label != null || iconSpan != null ?
      <Stack horizontal verticalAlign="center" horizontalAlign="space-between" tokens={stackTokens}>
        <span className="custom-label-text">{label}</span>
        {iconSpan != null
          ? (
            <span className="custom-label-error-icon-container">{iconSpan}</span>
          ) : null
        }
      </Stack>
      : null
  );
}