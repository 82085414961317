import 'date-fns';
import React, { Component } from 'react';
import moment from 'moment-with-locales-es6';
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  DateTimePicker as MUIDateTimePicker
} from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { DEFAULT_MATERIAL_THEME } from '../../../config';
import { isNotNullOrEmpty } from '../../functions/StringUtils';

class DateTimePicker extends Component {
  constructor(props) {
    super(props);

    let selectedDate = null;
    if (isNotNullOrEmpty(this.props.value)) {
      selectedDate = new Date(this.props.value);
    }

    if (selectedDate == null || isNaN(selectedDate) === true) {
      if (props.emptyDefault === true) {
        selectedDate = null;
      } else {
        selectedDate = new Date();
      }
    }

    this.state = {
      selectedDate: selectedDate
    }
  }

  componentDidMount() {
    if (this.props.fireOnChangeOnDidMount === true) {
      this.fireAfterOnChange();
    }
  }

  onChange = (selectedDate) => {
    this.setState({ selectedDate: selectedDate }, () => {
      this.fireAfterOnChange();
    });
  }

  fireAfterOnChange = () => {
    const value = this.state.selectedDate != null
      ? (this.state.selectedDate.toDate != null
        ? this.state.selectedDate.toDate().getTime()
        : this.state.selectedDate.getTime())
      : null;

    this.props.onChange(undefined, this.props.field, value);
  }

  parseRangeDate = (dateToParse) => {
    if (isNotNullOrEmpty(dateToParse)) {
      switch (dateToParse) {
        case "TODAY":
          return new Date();
        default:
          break;
      }
    }
    return undefined;
  }

  render() {
    const className = ["DatePickerContainer"];
    let formFeedBack = null;

    if (this.props.className != null) {
      className.push(this.props.className);
    }
    if (this.props.valid === false || (this.props.formFeedBack && this.props.formFeedBack !== "")) {
      className.push("invalidBox");
      formFeedBack = <p style={{ color: "rgb(168, 0, 0)" }}>{this.props.formFeedBack}</p>;
    }

    return <ThemeProvider theme={DEFAULT_MATERIAL_THEME}>
      <div className={className.join(" ")}>
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} >
          <MUIDateTimePicker
            {...this.props}
            label={this.props.onRenderLabel ? this.props.onRenderLabel() : ""}
            minDate={this.parseRangeDate(this.props.minDate)}
            maxDate={this.parseRangeDate(this.props.maxDate)}
            ampm={false}
            format={this.props.format ? this.props.format : "DD/MM/YYYY HH:mm"}
            value={this.state.selectedDate}
            onChange={this.onChange}
            variant="inline"
            PopoverProps={{ style: { zIndex: "1000000" } }}
          />
        </MuiPickersUtilsProvider>
        {formFeedBack}
      </div>
    </ThemeProvider>
  }
}

export default DateTimePicker;
