import React, { Component } from 'react';
import './CurrencyInput.css';
import { TextField } from 'office-ui-fabric-react';
import * as formatters from '../../functions/formatters';
import { getValueFromInput } from '../../functions/utility';
import {getLocalizedProperty} from "../../LocaleUtils";

const DEFAULT_DEFERRED_VALIDATION_TIME_MILLIS = 1000;
const NEGATIVE_PREFIX_VALUE = "-";

class CurrencyInput extends Component {
  constructor(props) {
    super(props);

    this.defaultValue = this.props.defaultValue !== undefined
      ? this.props.defaultValue
      : 0;
    this.isReadOnly = this.props.readOnly === true || this.props.forcedValue != null;
    this.allowNegativeValue = true;
    this.currentFormatter = this.props.formatter != null
      ? formatters[this.props.formatter]
      : formatters["decimalFormatter2"];

    const formattedValue = this.props.value != null && this.props.value !== ""
      ? this.currentFormatter(this.retrieveCalculatedCurrencyValue(this.props.value))
      : this.currentFormatter(this.defaultValue);

    this.state = {
      value: formattedValue
    };
  }

  componentDidMount() {
    if (this.props.fireOnChangeOnDidMount === undefined || this.props.fireOnChangeOnDidMount === true) {
      const calculatedCurrencyValue = this.retrieveCalculatedCurrencyValue(this.state.value);
      this.triggerPropsOnChange(calculatedCurrencyValue);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.isReadOnly = this.props.readOnly === true || this.props.forcedValue != null;
    this.allowNegativeValue = this.props.allowNegativeValue !== false;

    const formattedStateCurrencyValue = this.currentFormatter(this.retrieveCalculatedCurrencyValue(this.state.value));
    const formattedPropsCurrencyValue = this.currentFormatter(this.retrieveCalculatedCurrencyValue(this.props.value));
    if (NEGATIVE_PREFIX_VALUE === formattedStateCurrencyValue) {
      return;
    }

    if (prevProps.disabled !== this.props.disabled) {
      this.triggerPropsOnChange(this.props.value);
    }

    if (formattedStateCurrencyValue !== formattedPropsCurrencyValue) {
      this.setState({ value: formattedPropsCurrencyValue })
    }
  }

  onChangeHandler = (event) => {
    if (this.props.disabled === true || this.isReadOnly) {
      return;
    }

    const value = this.cleanNegativeValueIfNotAllowed(getValueFromInput(event));
    const calculatedCurrencyValue = this.retrieveCalculatedCurrencyValue(value);

    this.triggerPropsOnChange(calculatedCurrencyValue);
    this.setState({ value: value });
  }

  onFocusHandler = (event) => {
    if (this.props.disabled === true || this.isReadOnly) {
      return;
    }

    let calculatedCurrencyValue = this.retrieveCalculatedCurrencyValue(this.state.value);

    if (this.defaultValue === calculatedCurrencyValue) {
      calculatedCurrencyValue = "";
      this.triggerPropsOnChange(calculatedCurrencyValue);
    }

    this.setState({ value: calculatedCurrencyValue });
  }

  onBlurHandler = (event) => {
    if (this.props.disabled === true || this.isReadOnly) {
      return;
    }

    const formattedValue = this.props.value != null && this.props.value !== ""
      ? this.currentFormatter(this.props.value)
      : this.currentFormatter(this.defaultValue);
    const calculatedCurrencyValue = this.retrieveCalculatedCurrencyValue(formattedValue);

    this.triggerPropsOnChange(calculatedCurrencyValue);
    this.setState({
      value: formattedValue
    }, () => {
      const calculatedCurrencyValue = this.retrieveCalculatedCurrencyValue(this.state.value);
      this.triggerPropsOnBlur(calculatedCurrencyValue);
    });
  }

  retrieveCalculatedCurrencyValue = (value) => {
    let formattedValue = ('' + value).replace(/[^0-9,.-]+/g, "");
    formattedValue = formattedValue.replace(/[,]+/g, ".");
    formattedValue = formattedValue.replace(/(.*)\./, x => x.replace(/\./g, '') + '.');
    formattedValue = this.cleanNegativeValueIfNotAllowed(formattedValue);

    if (NEGATIVE_PREFIX_VALUE === formattedValue) {
      return formattedValue;
    }

    if (formattedValue === "") {
      formattedValue = this.defaultValue;
      return formattedValue;
    }

    formattedValue = Number(formattedValue);
    if (isNaN(formattedValue)) {
      formattedValue = this.defaultValue;
    }

    return formattedValue;
  }

  cleanNegativeValueIfNotAllowed = (value) => {
    if (this.allowNegativeValue === false) {
      value = value.replace(/[-]+/g, "");
    }

    return value;
  }

  triggerPropsOnChange = (value) => {
    if (NEGATIVE_PREFIX_VALUE === value) {
      return;
    }

    if (this.props.onChange != null) {
      this.props.onChange(null, this.props.field, value);
    }
  }

  triggerPropsOnBlur = (value) => {
    if (this.props.onBlur != null) {
      this.props.onBlur(null, this.props.field, value);
    }
  }

  render() {
    const {
      formFeedBack,
      warningMessage,
      field,
      cleanupOnPaste,
      deferredValidationTime,
      required,
      inputSuffix,
      forcedValue,
      maxLength,
      parsedLabel,
      customLabelRender,
      placeHolderClass,
      onPasteHandler,
      customClassName
    } = this.props;

    const className = ["currency-input"];
    const inputId = this.props.id != null ? this.props.id : ("inp-" + this.props.field);
    let iconProps = {};

    if (placeHolderClass != null) {
      className.push(placeHolderClass);
    }
    if (customClassName != null) {
      className.push(customClassName);
    }
    if (this.props.valid === false) {
      className.push("invalidBox");
    }
    if (this.isReadOnly) {
      className.push("read-only");
    }
    if (this.props.valid === true && warningMessage != null && warningMessage !== "") {
      className.push("warningBox");
    }
    if (this.props.showCleaner === true) {
      iconProps = {
        iconName: "ChromeClose",
        style: { pointerEvents: "auto", cursor: "pointer", fontSize: "10px" },
        onClick: () => this.onChangeHandler(null)
      }
    }

    return (
      <TextField
        disabled={this.props.disabled === true}
        errorMessage={formFeedBack}
        warningMessage={warningMessage}
        id={inputId}
        label={parsedLabel}
        onRenderLabel={customLabelRender}
        name={field}
        className={className}
        value={this.state.value}
        placeholder={getLocalizedProperty(this.props, "placeholder")}
        validateOnFocusOut={true}
        validateOnLoad={true}
        onFocus={this.onFocusHandler.bind(this)}
        onBlur={this.onBlurHandler.bind(this)}
        onChange={this.onChangeHandler.bind(this)}
        onPaste={!cleanupOnPaste ? null : onPasteHandler}
        deferredValidationTime={deferredValidationTime !== null ? deferredValidationTime : DEFAULT_DEFERRED_VALIDATION_TIME_MILLIS}
        required={required}
        suffix={inputSuffix}
        readOnly={this.isReadOnly}
        defaultValue={forcedValue ? forcedValue : null}
        maxLength={maxLength}
        tabIndex={this.isReadOnly ? "-1" : undefined}
        iconProps={{ ...iconProps }}
      />
    );
  }
}

export default CurrencyInput;