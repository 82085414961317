import { DetailsList, DetailsRow, CheckboxVisibility, DetailsListLayoutMode, CollapseAllVisibility } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ViewContext from "../../containers/ViewContext/ViewContext";
import * as formatters from '../../functions/formatters';
import { parseString } from '../../functions/utility';
import * as actions from '../../store/actions';
import './DataTable.css';
import DataTableBase from "./DataTableBase";
import DataTableTd from './TableData/DataTableTd';

class DataTable extends DataTableBase {

  buildActionData = (currentAction, object) => {
    if (currentAction.fieldList != null) {
      const selectedItemFields = {};
      currentAction.fieldList.forEach(field => {
        selectedItemFields[field] = [];
        this._selection.getSelectedIndices().forEach(selectedIndex => {
          const selectedItem = this._selection.getItems()[selectedIndex];
          if (this.props.groupedList) {
            selectedItemFields[field].push(selectedItem.item[Object.keys(selectedItem.item)[0]].props.object[field]);
          } else {
            selectedItemFields[field].push(selectedItem[Object.keys(selectedItem)[0]].props.object[field]);
          }
        });
      });
      if (currentAction.applyFiltersFields != null) {
        currentAction.applyFiltersFields.forEach(field => {
          this.props.viewState.applyFilters.forEach(element => {
            if (element.param === field) {
              selectedItemFields[field] = element.value
            }
          });
        })
      }
      return selectedItemFields;
    } else {
      return object;
    }
  }

  onRenderGroupHeader = (props) => {
    let groupRowClassName = null;
    const rowIndexModule = props.groupIndex % 2;
    if (this.props.style != null) {
      groupRowClassName = this.props.style.groupRowClassName + rowIndexModule;
    }

    this.props.groupColumns.forEach((element, index) => {

      let firstGroupRow = null
      if (this.state.items && props.group) {
        const temp1 = this.state.items[props.group.startIndex];
        if (temp1) {
          firstGroupRow = { ...temp1[Object.keys(temp1)[0]].props.object }
        }
      }

      if (element.field === "expandButton") {
        props.group.item[element.field + index] =
          (
            <DataTableTd
              {...this.props}
              title={undefined}
              titleLocaleKey={undefined}
              titleParseBeforeTranslate={undefined}
              key={index + props.group.isCollapsed}
              showDetailHandler={this.props.showDetailHandler}
              onIconButtonClick={() => props.onToggleCollapse(props.group)}
              iconName={props.group.isCollapsed ? "ChevronRightSmall" : "ChevronDownSmall"}
              style={{ fontSize: "12px" }}
              downloadFileHandler={this.onFileDownloadClick}
              openViewHandler={this.onOpenViewClick}
              sendData={this.sendData}
              sendDataConfirmDialog={this.sendDataConfirmDialog}
              sendDataPreActionDialog={this.sendDataPreActionDialog}
              prepareEngineWorkflow={this.prepareEngineWorkflow}
              onIconSwitchChange={this.onIconSwitchChange}
              loadData={this.loadData}
              onChange={(event, field, value, errors) => this.props.onChange(event, field, value, errors, this.props.dataField, index)}
              applyFilters={this.props.viewState ? this.props.viewState.applyFilters : []}
              columnStructure={element}
              {...element}
              className={element.className + " expandButton"}
              object={firstGroupRow}
              order={this.state.order}
              orderBy={this.state.orderBy}
            />
          );
      } else if (element.field === "itemsCount") {
        props.group.item[element.field + index] =
          (
            <DataTableTd
              {...this.props}
              title={undefined}
              titleLocaleKey={undefined}
              titleParseBeforeTranslate={undefined}
              key={index}
              showDetailHandler={this.props.showDetailHandler}
              onIconButtonClick={() => props.onToggleCollapse(props.group)}
              style={{ fontSize: "12px" }}
              downloadFileHandler={this.onFileDownloadClick}
              openViewHandler={this.onOpenViewClick}
              sendData={this.sendData}
              sendDataConfirmDialog={this.sendDataConfirmDialog}
              sendDataPreActionDialog={this.sendDataPreActionDialog}
              prepareEngineWorkflow={this.prepareEngineWorkflow}
              onIconSwitchChange={this.onIconSwitchChange}
              loadData={this.loadData}
              onChange={(event, field, value, errors) => this.props.onChange(event, field, value, errors, this.props.dataField, index)}
              columnStructure={element}
              {...element}
              applyFilters={this.props.viewState ? this.props.viewState.applyFilters : []}
              text={"( " + props.group.count + " )"}
              object={firstGroupRow}
              order={this.state.order}
              orderBy={this.state.orderBy}
            />
          );
      } else if (element.fieldType === "SUM_TOTAL") {
        let text = "" + props.group[element.field];
        let obj = { ...firstGroupRow };
        if (element.text != null) {
          let val = props.group[element.field];
          if (element.formatter != null) {
            val = formatters[element.formatter](val);
          }
          obj[element.field] = val;
          text = parseString(element.text, obj, element.formatterFieldMap);
        }
        props.group.item[element.field + index] =
          (
            <DataTableTd
              key={index}
              columnStructure={element}
              {...element}
              text={text}
              object={obj}
              order={this.state.order}
              orderBy={this.state.orderBy}
            />
          );
      }
    });
    return <DetailsRow
      {...props}
      item={props.group.item}
      itemIndex={props.group.itemIndex}
      className={groupRowClassName}
      onRenderCheck={(checkProps) => this.onRenderCheck(checkProps, props.group.itemIndex)}
      onClick={this.isInMultiselector ? () => { this.multiselectorOnClickRowHandler(props.group) } : null}
    />
  }

  onRenderRowHandler = (props) => {
    if (this.props.groupedList) {
      let itemsArray = [];
      for (let key in props.item) {
        const cellClassName = props.item[key].props.cellClassName != null ? parseString(props.item[key].props.cellClassName, props.item[key].props.object) : "";
        itemsArray.push(
          <div key={key} role="gridcell" className={"ms-DetailsRow-cell " + cellClassName}
            style={{ width: props.item[key].props.width }}>
            {props.item[key]}
          </div>
        );
      }
      const detailsRowClasses = ["ms-DetailsRow"];
      if (this.state.groups != null && this.props.style != null) {
        let currentGroupIndex = null;
        for (let i = 0; i < this.state.groups.length; i++) {
          const element = this.state.groups[i];
          let sum = element.startIndex + element.count;
          if ((props.itemIndex + 1) <= sum) {
            currentGroupIndex = i;
            break;
          }
        }
        const rowIndexModule = currentGroupIndex % 2;
        const rowClassName = this.props.rowClassName != null ? parseString(this.props.rowClassName, props.item[Object.keys(props.item)[0]].props.object) : "";
        detailsRowClasses.push(rowClassName);
        const currentDetailRowClassName = this.props.style.detailRowClassName + rowIndexModule;
        detailsRowClasses.push(currentDetailRowClassName);
      }
      return (
        <div className={detailsRowClasses.join(" ")} style={{ backgroundColor: "white" }}>
          <div className="ms-DetailsRow-fields" style={{ paddingLeft: this.props.childrenPaddingLeft != null ? this.props.childrenPaddingLeft : "96px", display: "flex", alignItems: "stretch" }} role="presentation">
            {itemsArray}
          </div>
        </div >
      );
    }
    const detailRowClasses = [];
    if (this.props.style != null && this.props.style.detailRowClassName != null) {
      const rowIndexModule = props.itemIndex % 2;
      const rowClassName = this.props.rowClassName != null ? parseString(this.props.rowClassName, props.item[Object.keys(props.item)[0]].props.object) : "";
      detailRowClasses.push(rowClassName);
      const currentDetailRowClassName = this.props.style.detailRowClassName + rowIndexModule;
      detailRowClasses.push(currentDetailRowClassName);
    }

    return (
      <DetailsRow
        {...props}
        onRenderCheck={(checkProps) => { return this.onRenderCheck(checkProps, props.itemIndex) }}
        className={detailRowClasses.join(" ")}
        styles={{ cell: { padding: 0 } }}
        onClick={this.isInMultiselector ? () => { this.multiselectorOnClickRowHandler(props) } : null}
      />
    )
  }

  retrieveDataTableContentData = (groups, items, indentWidth, optionalProps) => {
    return (
      <DetailsList
        groups={groups}
        disableSelectionZone={true}
        items={items}
        compact={true}
        selection={this.props.selectionEnabled ? this._selection : null}
        columns={this.state.columns}
        selectionMode={this.selectionMode}
        checkboxVisibility={this.props.selectionEnabled ? CheckboxVisibility.onHover : CheckboxVisibility.hidden}
        setKey="set"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={this.props.showHeaders === null || this.props.showHeaders}
        onRenderDetailsHeader={this.onRenderHeaderHandler}
        onColumnClick={this.onColumnClickHandler}
        selectionPreservedOnEmptyClick={true}
        onActiveItemChanged={this.onActiveItemChangedHandler}
        {...indentWidth}
        {...optionalProps}
        groupProps={{
          isAllGroupsCollapsed: true,
          onRenderHeader: this.onRenderGroupHeader,
          collapseAllVisibility: this.props.showCollapseAll == null || this.props.showCollapseAll ? CollapseAllVisibility.visible : CollapseAllVisibility.hidden
        }}
        onRenderRow={this.onRenderRowHandler}
      />
    );
  }

  retrieveDataTableClassName = () => {
    const dataTableClassName = ["DataTable"];

    if (this.props.pStructure.dataTableClassName != null) {
      dataTableClassName.push(this.props.pStructure.dataTableClassName);
    }
    if (this.props.style != null && this.props.style.className != null) {
      dataTableClassName.push(this.props.style.className);
    }

    return dataTableClassName;
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    dataTable: state.dataTable,
    currentLocale: state.auth.currentLocale,
    homeOperativaState: state.homeOperativa
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setCustomComponentState: (state) => dispatch(actions.setCustomComponentState({ state: state })),
    setPageState: (state) => dispatch(actions.setPageState({ state: state })),
    setDataTableState: (state) => dispatch(actions.setDataTableState({ state: state })),
    hideSearchModal: () => dispatch(actions.hideSearchModal()),
    showLongOperationWaitingModal: (percent, percentTitle, percentDescription) => dispatch(actions.showLongOperationWaitingModal({ percent: percent, percentTitle: percentTitle, percentDescription: percentDescription })),
    hideLongOperationWaitingModal: () => dispatch(actions.hideLongOperationWaitingModal()),
    showSearchModal: (search, mainStyle, objectId, loadedObject) => dispatch(actions.showSearchModal({ search: search, mainStyle: mainStyle, objectId: objectId, loadedObject: loadedObject })),
    showIframeModal: (url, title, width, height, updatePageOnClose) => dispatch(actions.showIframeModal({ url: url, title: title, width: width, height: height, updatePageOnClose: updatePageOnClose }))
  }
}

DataTable.contextType = ViewContext;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataTable));