import React, { PureComponent } from 'react';
import { Icon } from 'office-ui-fabric-react';

export class WagxIcon extends PureComponent {
    constructor(props) {
        super(props);

        this.iconName = this.props.iconName;
        this.iconFamily = this.props.iconFamily;
        this.title = this.props.title;
        this.className = this.props.className;

        this.state = {
            module: null,
            isMounted: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.isMounted) {
            this.loadComponent();
        }
    }

    componentDidMount() {
        this.loadComponent();
    }

    componentWillUnmount() {
        this.setState({ isMounted: false });
    }

    loadComponent() {
        switch (this.iconFamily) {
            case 'fa':
                import(
                    /* webpackChunkName: "react-icons-fa" */
                    /* webpackMode: "lazy" */
                    'react-icons/fa/index'
                ).then(module => {
                    this.setState({ module: module[this.iconName], isMounted: true });
                });
                break;
            case 'md':
                import(
                    /* webpackChunkName: "react-icons-md" */
                    /* webpackMode: "lazy" */
                    'react-icons/md/index'
                ).then(module => {
                    this.setState({ module: module[this.iconName], isMounted: true });
                });
                break;
            default:
                this.setState({ isMounted: true });
                break;
        }
    }

    render() {

        let curIcon = null;

        if (!this.iconName || !this.state.isMounted) {
            return null;
        }

        if (this.iconFamily) {
            const curModule = this.state.module;
            if (!curModule) {
                return null;
            } else {
                curIcon = <div title={this.title} className={this.className}> {curModule()} </div>
            }
        } else {
            curIcon = <Icon iconName={this.iconName} title={this.title} className={this.className} />;
        }

        return curIcon;
    }
}

export default WagxIcon;