import React from 'react';
import { IconButton } from 'office-ui-fabric-react';
import "./WagxExpandPanel.css";
import { parseConditionString } from '../../functions/utility';
import { getLocalizedProperty } from '../../LocaleUtils';
import WagBaseComponent from '../WagBaseComponent/WagBaseComponent';

class WagxExpandPanel extends WagBaseComponent {
  constructor(props) {
    super(props);
    this.icon = props.icon ? props.icon : "Add";
    this.expandedIcon = props.expandedIcon ? props.expandedIcon : "Remove";
    this.state = {
      expanded: props.expanded ? props.expanded : false,
      updated: false
    }
  }

  componentDidMount = () => {
    super.componentDidMount();
  }

  
  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render = () => {

    const expanded = this.state.expanded;
    const component = <div className="WagxExpandPanel" style={this.props.style}>
      <div className={this.props.headerClassName ? this.props.headerClassName : "WagxExpandPanelHeader"}>
        {getLocalizedProperty(this.props, "headerText", this.props.headerText)}{this.props.elements ? " (" + this.props.elements.length + ")" : null}
        <span className="expand-button">
          <IconButton iconProps={{ iconName: expanded ? this.expandedIcon : this.icon }} onClick={(event) => { event.stopPropagation(); this.setState({ expanded: !expanded }) }} />
        </span>
      </div>
      {expanded ? <div className={this.props.bodyClassName ? this.props.bodyClassName : "WagxExpandPanelBody"}>{this.props.children}</div> : null}
    </div>
    return (this.props.elements != null && this.props.elements.length > 0) || this.props.forceRender ? component : null;
  }


  componentDidUpdate = () => {
    if (this.props.expandedIf) {
      this.props.elements.forEach(element => {
        // eslint-disable-next-line 
        let expand = eval(parseConditionString(this.props.expandedIf, element, null, false));
        if (expand) {
          if (this.state.updated) return false;
          this.setState({
            expanded: true,
            updated: true
          });
          return false;
        }
      })
    }
  }
}

export default WagxExpandPanel;