import { computeFunctionBody, getInternationalizationInfo } from '../../functions/utility';
import { HIDDEN as INPUT_HIDDEN, HIDDEN_EMPTY as INPUT_HIDDEN_EMPTY } from '../GenericInput/GenericInputTypes';

export const generateGridColumnSizeClassName = (input, getObject) => {
  const sizeList = [
    {
      inputSize: "xs",
      size:"sm"
    },
    {
      inputSize: "sm",
      size:"sm"
    }, {
      inputSize: "md",  
      size: "md"
    }, 
    {
      inputSize:"lg",
      size: "lg"
    }, 
    {
      inputSize: "xl",
      size: "xl"
    }, 
    {
      inputSize:"xxl",
      size: "xxl"
    }, 
    {
      inputSize: "xxxl",
      size: "xxxl"
    }
  ];
  const classNames = ["ms-Grid-col"];

  sizeList.forEach((sizeItem) => {
    const sizeForInput = input[sizeItem.inputSize];

    if (sizeForInput != null) {
      if (sizeForInput.sizeFunction) {
        const object = getObject != null ? getObject() : null;

        try {
          const funcBody = computeFunctionBody(sizeForInput.sizeFunction);
          // eslint-disable-next-line 
          const func = new Function("object", funcBody);
          classNames.push("ms-" + sizeItem.size + func(object != null ? object : {}));
        } catch (e) {
          throw new Error(e.message + " sizeFunction Probabile errore di sinstassi " + JSON.stringify(sizeForInput));
        }
      } else if (sizeForInput.size != null) {
        classNames.push("ms-" + sizeItem.size + sizeForInput.size);
      }

      if (sizeForInput.offsetFunction) {
        const object = getObject != null ? getObject() : null;

        try {
          const funcBody = computeFunctionBody(sizeForInput.offsetFunction);
          // eslint-disable-next-line 
          const func = new Function("object", funcBody);
          classNames.push("ms-" + sizeItem.size + "Push" + func(object != null ? object : {}));
        } catch (e) {
          throw new Error(e.message + " offsetFunction Probabile errore di sinstassi " + JSON.stringify(sizeForInput));
        }
      } else if (sizeForInput.offset != null) {
        classNames.push("ms-" + sizeItem.size + "Push" + sizeForInput.offset);
      }
    }
  });

  return classNames.join(" ");
}

export const generateFormGroupGridColumnSizeClassName = (formGroup, getObject) => {
  const sizeList = [
    {
      inputSize: "xs",
      size:"sm"
    },
    {
      inputSize: "sm",
      size:"sm"
    }, {
      inputSize: "md",  
      size: "md"
    }, 
    {
      inputSize:"lg",
      size: "lg"
    }, 
    {
      inputSize: "xl",
      size: "xl"
    }, 
    {
      inputSize:"xxl",
      size: "xxl"
    }, 
    {
      inputSize: "xxxl",
      size: "xxxl"
    }
  ];
  const classNames = [];

  sizeList.forEach((sizeItem) => {
    const sizeForFormGroup = formGroup[sizeItem.inputSize];

    if (sizeForFormGroup != null) {
      if (classNames.indexOf("ms-Grid-col") === -1) {
        classNames.push("ms-Grid-col");
      }

      if (sizeForFormGroup.sizeFunction) {
        const object = getObject != null ? getObject() : null;

        try {
          const funcBody = computeFunctionBody(sizeForFormGroup.sizeFunction);
          // eslint-disable-next-line 
          const func = new Function("object", funcBody);
          classNames.push("ms-" + sizeItem + func(object != null ? object : {}));
        } catch (e) {
          throw new Error(e.message + " sizeFunction Probabile errore di sinstassi " + JSON.stringify(sizeForFormGroup));
        }
      } else if (sizeForFormGroup.size != null) {
        classNames.push("ms-" + sizeItem.size + sizeForFormGroup.size);
      }
      if (sizeForFormGroup.offset != null) {
        classNames.push("ms-" + sizeItem.size + "Push" + sizeForFormGroup.offset);
      }
    }
  });

  return classNames;
}

export const removeHiddenInputValueFromObject = (fieldInputMap, object) => {
  if (object == null) {
    return;
  }

  let fieldToRemove = [];
  let fieldToKeep = [];

  for (let field in object) {
    if (!fieldInputMap.hasOwnProperty(field)) {
      continue;
    }

    fieldInputMap[field].forEach(input => {
      if (INPUT_HIDDEN === input.type || INPUT_HIDDEN_EMPTY === input.type) {
        fieldToRemove.push(field)
      } else {
        fieldToKeep.push(field);
      }
    });
  }

  fieldToRemove.forEach((field) => {
    if (fieldToKeep.indexOf(field) === -1) {
      delete object[field];
    }
  });
}

export const computeShowIf = (formElement, funcBody, object, profiles) => {
  try {
    // eslint-disable-next-line 
    const func = new Function("object", "profiles", "internationalizationInfo", funcBody);
    formElement.isVisible = func(object == null ? {} : object, profiles, getInternationalizationInfo());
    return func;
  } catch (e) {
    throw new Error(e.message + " SHOWIF Probabile errore di sinstassi " + JSON.stringify(formElement));
  }
}

export const computeEnableIf = (formElement, funcBody, object, profiles) => {
  try {
    // eslint-disable-next-line 
    const func = new Function("object", "profiles", "internationalizationInfo", funcBody);
    formElement.isEnabled = func(object == null ? {} : object, profiles, getInternationalizationInfo());
    return func;
  } catch (e) {
    throw new Error(e.message + " ENABLEIF Probabile errore di sinstassi " + JSON.stringify(formElement));
  }
}

export const computeReadOnlyIf = (formElement, funcBody, object, profiles) => {
  try {
    // eslint-disable-next-line 
    const func = new Function("object", "profiles", "internationalizationInfo", funcBody);
    formElement.isReadOnly = func(object == null ? {} : object, profiles, getInternationalizationInfo());
    return func;
  } catch (e) {
    throw new Error(e.message + " REDONLYIF Probabile errore di sinstassi " + JSON.stringify(formElement));
  }
}