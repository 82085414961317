import axios from '../../axios-wagx-web';
import * as actionTypes from "./action-types";
import { dismissModal } from "./modal";
import intl from 'react-intl-universal';
import { showMessageModal } from '../../functions/utility';
import { MessageSeverityEnum } from '../../enums/MessageSeverityEnum';
import { parseParameter } from '../utils';

const ERR_EXPIRED_PASSWORD = "EXPIRED_PASSWORD";

export const resetAuthState = () => {
  return { type: actionTypes.RESET_AUTH_STATE };
}

export const loginSuccess = (appState) => {
  if (!appState.viewMap) {
    const viewMap = {};
    if (appState != null && appState.views != null) {
      appState.views.forEach(item => {
        parseView(item);
        viewMap[item.id] = item;
      });
    }
    appState.viewMap = viewMap;
    appState.views = undefined;
  }
  return {
    type: actionTypes.LOGIN_SUCCESS,
    appState: appState
  };
};

export const parseView = (item) => {
  try {
    item.pStructure = JSON.parse(parseParameter(item.structure));
    item.structure = undefined;
    item.applyFilters = undefined; // resetto applyFilters della vista perché vengono già gestiti dal frontend JSON.parse(item.applyFilters);
  } catch (e) {
    console.error("Structure of view with id " + item.id + " is not parsable!", e);
  }
  try {
    if (item.masterView) {
      item.masterView.pStructure = JSON.parse(item.masterView.structure);
      item.masterView.structure = undefined;
    }
  } catch (e) {
    console.error("Structure of view with id " + item.masterView.id + " is not parsable!", e);
  }
}

export const localeChanged = (currentLocale) => {
  return {
    type: actionTypes.LOCALE_CHANGED,
    currentLocale: currentLocale
  };
};

export const loginStart = () => {
  return {
    type: actionTypes.LOGIN_START
  };
};

export const loginReset = () => {
  return {
    type: actionTypes.LOGIN_RESET
  };
};

export const loginFail = (error) => {
  return {
    type: actionTypes.LOGIN_FAIL,
    error: error
  };
};

export const login = (authData) => {
  return dispatch => {
    dispatch(loginStart());
    dispatch(dismissModal());
    axios.post("/public/login?lang=" + intl.getInitOptions().currentLocale, authData)
      .then(response => {
        if (response.data.session) {
          dispatch(loginSuccess(response.data));
        } else {
          dispatch(loginFail());
        }
      })
      .catch(err => {
        if (err.response != null && err.response.data != null) {
          if (err.response.data.error === ERR_EXPIRED_PASSWORD) {
            dispatch(hasToResetPassword(err.response.data));
          } else if (err.response.data === 401) {
            dispatch(loginFail(err.response));
          } else {
            dispatch(loginFail(err.response));
          }
        } else {
          dispatch(loginFail(err.response));
        }
      });
  };
};

export const logout = () => {
  return dispatch => {
    dispatch(loginStart());
    axios.get("/logout")
      .then(response => {
        dispatch(logoutSuccess());
      }).catch(error => {

      });
  }
};

export const authCheckState = (appState) => {
  return dispatch => {
    dispatch(authCheckStateStart());
    axios.get("/refreshSession")
      .then(response => {
        if (response.data.success) {
          dispatch(loginSuccess(response.data.value != null && appState == null ? response.data.value : appState));
        } else {
          dispatch(logout());
        }
      })
      .catch(error => {
        dispatch(logout());
      });
  };
}

export const authCheckStateStart = () => {
  return {
    type: actionTypes.AUTH_CHECK_STATE_START
  };
};

export const logoutSuccess = () => {
  return {
    type: actionTypes.LOGOUT
  };
}

export const hasToResetPassword = (data) => {
  return {
    type: actionTypes.HAS_TO_RESET_PASSWORD,
    data: data
  };
}

export const setSessionEnded = (sessionEnded) => {
  return {
    type: actionTypes.SET_SESSION_ENDED,
    sessionEnded: sessionEnded
  };
}

export const updateView = (viewId, newView) => {
  return dispatch => {
    const data = { viewId: viewId, structure: newView };
    const warningMessage = {
      messageKey: intl.get("ViewService.ERROR_UPDATE_VIEW").d("Errore aggiornamento vista in Redux. E' consigliato effettuare nuovamente la login"),
      severity: MessageSeverityEnum.WARNING.key
    };

    axios.post("/views/updateView", data)
      .then(response => {
        if (response.data.success) {
          if (response.data.value != null) {
            dispatch(updateViewSuccess(response.data.value));
          }
        } else if (response.data.responseMessages != null) {
          showMessageModal(true, null, response.data.responseMessages, false);
        } else {
          showMessageModal(true, null, warningMessage, false);
        }
      }).catch(err => {
        console.warn(warningMessage.messageKey, "\nErrorMessage: '" + err.message + "'");
        showMessageModal(true, null, warningMessage, false);
      });
  };
};

const updateViewSuccess = (model) => {
  return {
    type: actionTypes.UPDATE_VIEW,
    viewId: model.updatedViewId,
    newStructureView: model.updatedStructure,
    updatedViewIds: model.updatedViewIds
  };
}