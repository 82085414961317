import React, { PureComponent, Suspense } from 'react';
import Spinner from './UI/Spinner/Spinner';

const divStyle = { height: "100%" };

export class Dynamic extends PureComponent {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = {
      module: null,
      path: null,
      isMounted: false,
      data: props.data,
      loadData: props.flagDataLoaded,
      parsedTitle: props.parsedTitle
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.view.id !== nextProps.view.id) {
      this.setState({ isMounted: false });
    }

    // eslint-disable-next-line 
    if ((!this.state.data && nextProps.data) || (this.state.data != nextProps.data)) {
      this.setState({ data: nextProps.data, loadData: nextProps.flagDataLoaded, parsedTitle: nextProps.parsedTitle });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.isMounted) {
      this.loadComponent();
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadComponent();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadComponent() {
    const { component } = this.props.view;
    const componentPath = component.path;
    const module = React.lazy(() => import("../" + componentPath));
    this.setState({ module: module, entity: this.props.view.entity, isMounted: true });
  }

  render() {
    const { module: Component } = this.state;
    const content = Component && <Component
      {...this.props}
      data={this.state.data}
      flagDataLoaded={this.state.loadData}
      parsedTitle={this.state.parsedTitle}
    />;
    return (
      <Suspense fallback={this.props.noSpinner === true ? null : <Spinner />}>
        {this.props.wrapInDiv == null || this.props.wrapInDiv === true ?
          <div className="ms-sm12" style={divStyle}>
            {content}
          </div> :
          <React.Fragment>
            {content}
          </React.Fragment>
        }
      </Suspense>
    )
  }
}

export default Dynamic;
