import { updateObject } from '../utils'
import * as actionTypes from '../actions/action-types';

const initialState = {
  section: -1,
  expandedMenus: [],
  activeMenu: -1,
  clippedMenu: true,
  openedMenu: true,
  firstOpen: true
};

const collapseMenu = (state, action) => {
  let menuArray = [...state.expandedMenus];
  const menuIdx = menuArray.findIndex(menu => menu === action.menu);
  if (menuIdx !== -1) menuArray.splice(menuIdx, 1);
  return updateObject(state, { expandedMenus: menuArray });
}
const expandMenu = (state, action) => {
  let menuArray = [...state.expandedMenus];
  const menuIdx = menuArray.findIndex(menu => menu === action.menu);
  if (menuIdx === -1) menuArray = [...menuArray, action.menu];
  return updateObject(state, { expandedMenus: menuArray });
}
const setActiveMenu = (state, action) => {
  return updateObject(state, { activeMenu: action.menu });
}
const toggleMenuBar = (state, action) => {
  return updateObject(state, { clippedMenu: action.menu });
}
const openMenu = (state, action) => {
  return updateObject(state, { openedMenu: action.menu });
}
const firstOpen = (state, action) => {
  return updateObject(state, { firstOpen: action.menu });
}
const logout = (state, action) => {
  return updateObject(state, initialState);
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGOUT: return logout(state, action);
    case actionTypes.EXPAND_MENU: return expandMenu(state, action);
    case actionTypes.COLLAPSE_MENU: return collapseMenu(state, action);
    case actionTypes.SET_ACTIVE_MENU: return setActiveMenu(state, action);
    case actionTypes.TOGGLE_MENU_BAR: return toggleMenuBar(state, action);
    case actionTypes.OPEN_MENU: return openMenu(state, action);
    case actionTypes.FIRST_OPEN: return firstOpen(state, action);
    default:
      return state;
  }
};

export default reducer;