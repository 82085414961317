import moment from 'moment-with-locales-es6';
import intl from 'react-intl-universal'
import React from 'react';
import { Icon } from 'office-ui-fabric-react';
import { capitalize } from 'lodash';
import { getLocalizedProperty } from '../LocaleUtils';
import { isNotNullOrEmpty, isNullOrEmpty } from './StringUtils';
// no-new-wrappers
export const capitalizeStringFormatter = (value) => {
  //Va migliorato per gestire la possibilita di "stringa con piu parole"
  if (value == null) {
    return "";
  }
  return capitalize(value);
}
export const defaultNumberFormatter = (value) => {
  if (isNaN(value) || value === "") {
    return "";
  }
  return + value;
}
export const defaultStringFormatter = (value) => {
  let newValue = value != null ? value : "";
  newValue = newValue == null ? "" : newValue;
  return newValue;
}
export const defaultBooleanFormatter = (value) => {
  return value;
}
export const stringDateLocaleFormat = (value) => {
  if (value == null || value === "") {
    return "";
  }
  const newValue = new Date(value).toLocaleString();
  return newValue;
}
export const stringDateLocalDateFormat = (value) => {
  if (value == null || value === "") {
    return "";
  }
  const m = moment(value);
  return m.format("L");
}

export const stringDateTimeLocalDateFormat = (value) => {
  if (value == null || value === "") {
    return "";
  }
  const m = moment(value);
  return m.format("DD/MM/YYYY H:mm");
}

export const stringDateLocalTimeFormat = (value) => {
  if (value == null || value === "") {
    return "";
  }
  const m = moment(value);
  return m.format("H:mm");
}

export const parseDateFromMillisec = (value) => {
  if (value == null || value === "") {
    return "";
  }

  const n = Number(value);
  if (isNaN(n)) {
    return new Date(value);
  } else {
    return new Date(n);
  }
}

export const customDateFormatter = (value, formatterParameters) => {
  if (isNullOrEmpty(value)) {
    return "";
  }

  let strDate = value;
  try {
    const m = moment(value);
    if (m.isValid()) {
      let format = "L";
      if(isNotNullOrEmpty(formatterParameters.format)) {
        format = formatterParameters.format;
      }
      strDate = m.format(format);
    } else {
      strDate = "";
    }
  } catch (exception) {
    console.warn(exception);
  }

  return strDate;
} 

export const defaultDateFormatter = (date) => {
  if (date == null || date === "") {
    return "";
  }
  let strDate = date;
  try {
    const m = moment(date);
    if (m.isValid()) {
      strDate = m.format("L");
    } else {
      strDate = "";
    }
  } catch (exception) {
    console.warn(exception);
  }
  return strDate;
};

export const momentDateFormatter = (value) => {
  if (!value) {
    return undefined;
  }
  const date = moment(value, "x");
  return date;
}

export const momentDateToStringFormatter = (value) => {
  const date = moment(value, ["x", "YYYY-MM-DD"]);
  return date.format("YYYY-MM-DD");
}

export const momentDateToStringFormatter2 = (value) => {
  if (value == null || value === "") return "";
  const date = moment(value, ["x", "DD/MM/YYYY"]);
  return date.format("DD/MM/YYYY");
}

const numberFormatter = (value, numDecimal) => {
  if (value == null) return "";
  const locale = intl.getInitOptions().currentLocale.replace("_", "-");
  return isNaN(Number(value)) ? value
    : Number(value).toLocaleString(locale, { maximumFractionDigits: numDecimal, minimumFractionDigits: numDecimal });
}

export const intFormatter = (value) => {
  return numberFormatter(value, 0);
}

export const decimalFormatter1 = (value) => {
  return numberFormatter(value, 1);
}

export const decimalFormatter2 = (value) => {
  return numberFormatter(value, 2);
}

export const decimalFormatter4 = (value) => {
  return numberFormatter(value, 4);
}

const forceNumberFormatter = (value, numDecimal) => {
  // remove all NaN value
  // eslint-disable-next-line
  let newVal = ("" + value).replace(/[^0-9\.,+-]*/g, "");
  // format number
  newVal = numberFormatter(newVal, numDecimal);
  // remove thousand separator
  const thousandSeparator = whatThousandSeparator();
  if (newVal.indexOf(thousandSeparator) > -1) {
    const regExp = new RegExp(thousandSeparator === "." ? "\\" + thousandSeparator : thousandSeparator, 'g');
    newVal = newVal.replace(regExp, "");
  }
  return newVal;
}

const whatThousandSeparator = () => {
  return numberFormatter(1000, 0).substring(1, 2);
}

export const forceIntFormatter = (value) => {
  return forceNumberFormatter(value, 0);
}

export const forceDecimalFormatter = (value) => {
  return forceNumberFormatter(value, 1);
}

export const currencyFormatter = (value, currency) => {
  if (value == null || currency == null || currency === "") return "";
  const locale = intl.getInitOptions().currentLocale.replace("_", "-");
  return isNaN(Number(value)) ? value : Number(value).toLocaleString(locale, { style: "currency", currency: currency, maximumFractionDigits: 2, minimumFractionDigits: 2 });
}

export const eurCurrencyFormatter = (value) => {
  return currencyFormatter(value, "EUR");
}

export const usdCurrencyFormatter = (value) => {
  return currencyFormatter(value, "USD");
}

export const bgnCurrencyFormatter = (value) => {
  return currencyFormatter(value, "BGN");
}

export const yenCurrencyFormatter = (value) => {
  return currencyFormatter(value, "YEN");
}

export const eurCurrencyFormatterAdv = (value, parameters) => {
  if (value == null) return parameters.zeroAsDash ? "-" + parameters.flagCurrencySymbol ? " €" : "" : "";
  const locale = intl.getInitOptions().currentLocale.replace("_", "-");
  if (typeof parameters.flagCurrencySymbol === 'undefined' || parameters.flagCurrencySymbol == null) {
    parameters.flagCurrencySymbol = true;
  }
  if (isNaN(Number(value))) {
    if (parameters.zeroAsDash) {
      return "-" + (parameters.flagCurrencySymbol ? " €" : "");
    } else {
      return value + (parameters.flagCurrencySymbol ? " €" : "");
    }
  } else {
    if (value === 0 && parameters.zeroAsDash) {
      return "-" + (parameters.flagCurrencySymbol ? " €" : "");
    } else {
      return Number(value).toLocaleString(locale, { style: "currency", currency: "EUR", maximumFractionDigits: 2, minimumFractionDigits: 2 });
    }
  }
}

export const decimalFormatter = (value) => {
  if (value == null) return "";
  return isNaN(Number(value)) ? value : Number(value).toLocaleString();
}

export const upperCaseFormatter = (value) => {
  if (value == null) return "";
  return value.trim().toUpperCase();
}

export const percFormatter = (value) => {
  if (value == null) return "";
  const locale = intl.getInitOptions().currentLocale.replace("_", "-");
  return isNaN(Number(value)) ? value : Number(value).toLocaleString(locale, { style: "percent" });
}

export const percFormatterDividedBy100 = (value) => {
  if (value == null) return "";
  const locale = intl.getInitOptions().currentLocale.replace("_", "-");
  return isNaN(Number(value)) ? value : Number(value / 100).toLocaleString(locale, { style: 'percent', minimumFractionDigits: 2 });
}

export const JSONLocaleFormatter = (value, parameters) => {
  // N.B.: TENERE AGGIORNATO AL JSONLocaleFormatter.java DI WAGX-FRONTEND
  if (value == null || value === "") return "";

  let val = null;

  try {
    val = JSON.parse(value);
  } catch (ex) {
    console.warn(ex);
    return "";
  }

  let formattedValue = "";
  if (Array.isArray(val)) {
    let separator = intl.get("Clever.trattino").d(" - ");
    if (parameters?.separator != null) {
      separator = parameters.separator;
    }

    formattedValue = val.map(element => {
      let valueToTranslate = element.value;

      if (element.translate !== false) {
        return localeFormatter(valueToTranslate, { substitutionMap: element.substitutionMap });
      } else {
        return valueToTranslate;
      }
    }).join(separator);
  } else {
    formattedValue = localeFormatter(val.value, { substitutionMap: val.substitutionMap });
  }

  return formattedValue;
}

export const localeFormatter = (value, parameters) => {
  // N.B.: TENERE AGGIORNATO AL LocaleFormatter.java DI WAGX-FRONTEND
  if (value == null || value === "") return "";
  let val = value;
  if (parameters?.prefix != null) {
    val = parameters.prefix + val;
  }
  if (parameters?.suffix != null) {
    val = val + parameters.suffix;
  }

  let returnValue = null;
  if (parameters?.substitutionMap != null && typeof parameters.substitutionMap === "object") {
    returnValue = intl.get(val, parameters.substitutionMap).d(value);
  } else {
    returnValue = intl.get(val).d(value);
  }

  return returnValue;
}

export const checkFormatter = (value) => {
  if (value === 1 || value === "1" || value === true || value === "true") {
    return (<Icon iconName="CheckMark" style={{ color: "green" }} />);
  } else if (value === 0 || value === "0" || !value || value === "false") {
    return (<Icon iconName="Cancel" style={{ color: "red" }} />);
  }
  return value;
}

// Deprecato non usare più
export const inputViewPercentage = (value) => {
  try {
    if (value == null) {
      return "";
    }
    let newVal = value;
    if (!isNaN(+newVal)) {
      newVal = (((newVal + 1) * 100) - 100).toFixed(2); // Avoid 0.01 javascript issue
    }
    newVal = ("" + newVal);
    if (newVal.endsWith(".00")) {
      newVal = newVal.replace(".00", "");
    }
    newVal = newVal.replace(".", ",");
    return newVal;
  } catch (e) {
    return value;
  }
}

// Deprecato non usare più
export const inputStorePercentage = (value) => {
  try {
    let newVal = value;
    newVal = ("" + newVal).replace(",", ".");
    if (!isNaN(+newVal)) {
      newVal = newVal / 100;
    }
    return newVal;
  } catch (e) {
    return value;
  }
}

// Deprecato non usare più
export const inputViewCurrency = (value) => {
  try {
    if (value == null) {
      return "";
    }
    let newVal = value;
    if (isNaN(newVal)) {
      return value;
    }
    newVal = (+value).toFixed(2);
    if (newVal.endsWith(".00")) {
      newVal = newVal.replace(".00", "");
    }
    newVal = newVal.replace(".", ",");

    return newVal;
  } catch (e) {
    return value;
  }
}

// Deprecato non usare più
export const inputStoreCurrency = (value) => {
  try {
    let newVal = value;
    newVal = ("" + newVal).replace(",", ".");
    newVal = (+newVal);
    if (isNaN(newVal)) {
      return value;
    }
    newVal = (+newVal).toFixed(2);
    return newVal;
  } catch (e) {
    return value;
  }
}

// Deprecato non usare più
export const inputViewNumber = (value) => {
  try {
    if (value == null) {
      return "";
    }
    let newVal = value;
    newVal = ("" + newVal);
    if (newVal.endsWith(".00")) {
      newVal = newVal.replace(".00", "");
    }
    newVal = newVal.replace(".", ",");
    return newVal;
  } catch (e) {
    return value;
  }
}

// Deprecato non usare più
export const inputStoreNumber = (value) => {
  try {
    let newVal = value;
    newVal = ("" + newVal).replace(",", ".");
    return newVal;
  } catch (e) {
    return value;
  }
}

export const truncate100StringFormatter = (value) => {
  return truncateStringFormatter(value, { size: 110 });
}

export const truncateStringFormatter = (value, parameters) => {
  let size = 110;
  let elipsis = "...";
  if (parameters && parameters.size && typeof parameters.size === 'number') {
    size = parameters.size;
  }
  if (parameters && parameters.elipsis) {
    elipsis = parameters.elipsis;
  }
  let newValue = value ? value : "";
  newValue = newValue == null ? "" : newValue;
  if (newValue.length > size) {
    newValue = newValue.substring(0, size) + elipsis;
  }
  return newValue;
}

export const removeSpacesStringFormatter = (value) => {
  return defaultStringFormatter(value).replace(/ /g, "");
}

export const removeHtmlStringFormatter = (value) => {
  const d = document.createElement("div");
  d.innerHTML = value;
  return d.innerText;
}

export const localizedPropertyFormatter = (value, parameters, object) => {
  return parameters != null
    ? getLocalizedProperty(parameters, 'value', value, object)
    : value;
}

/*
  FUNZIONE CHE FORMATTA UN VALORE IN UN NUMERO NATURALE POSITIVO (0,1,2 ...)
  INPUT:
    - value: il valore da formattare
  OUTPUT:
    (number)
*/
export const naturalPositiveFormatter = (value) => {

  let result = 0;

  //Filtro i valori inseriti dall'utente (accetto solo numeri interi positivi)
  let newVal = ("" + value).replace(/[^0-9]*/g, "");

  //Controllo il valore filtrato
  if (newVal != null && !isNaN(Number(newVal))) {
    result = Number(newVal);
  }

  return result;
}
