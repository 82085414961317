import * as actionTypes from "./action-types";
export const saveViewState = (viewState) => {
  return {
    type: actionTypes.SAVE_VIEW_STATE,
    viewState: viewState
  }
}

export const showLongOperationWaitingModal = (modalProps) => {
  return {
    type: actionTypes.SHOW_LONG_OPERATION_WAITING_MODAL,
    ...modalProps
  }
}

export const hideLongOperationWaitingModal = () => {
  return {
    type: actionTypes.CLOSE_LONG_OPERATION_WAITING_MODAL
  }
}