import { TextField, Label, Stack, Icon } from "office-ui-fabric-react";
import React, { PureComponent } from "react";
import { getLocalizedProperty } from '../../LocaleUtils';
import * as evaluateFunctions from '../../functions/utility';
import './WagxTextArea.css';

class WagxTextArea extends PureComponent {

  customLabelRender = (props, defaultRender) => {
    let label = null;
    if (defaultRender == null) {
      label = getLocalizedProperty(this.props, "label", null, this.props.object);
      if (label != null && label !== "") {
        label = (<Label disabled={this.props.disabled} required={this.props.required}>{label}</Label>);
      }
    } else {
      label = defaultRender(props);
    }

    const errorMessage = this.props.errorMessage ? this.props.errorMessage : this.props.formFeedBack;
    const stackTokens = { childrenGap: 4, maxWidth: 300 };
    const iconProps = { iconName: 'Error', style: { color: "rgb(168,0,0)", fontSize: "14px" } };
    return (
      label != null || (errorMessage != null && errorMessage !== "") ?
        <Stack horizontal verticalAlign="center" horizontalAlign="space-between" tokens={stackTokens}>
          {label}
          {errorMessage != null && errorMessage !== "" ?
            <Icon
              {...iconProps}
              title={errorMessage}
              ariaLabel={errorMessage}
            />
            : null}
        </Stack>
        : null
    );
  };

  textAreaOnChange = (event) => {
    let value = evaluateFunctions.getValueFromInput(event);
    if (this.props.maxLength != null) {
      if (value.length > this.props.maxLength) {
        value = value.substring(0, this.props.maxLength);
      }
    }
    this.props.onChange(event, value);
  }

  render() {
    const label = getLocalizedProperty(this.props, "label");
    const placeholder = getLocalizedProperty(this.props, "placeholder");

    let className = "wagx-text-area";
    if (this.props.className != null) {
      className += " " + this.props.className;
    }

    return (
      <TextField
        disabled={this.props.disabled}
        errorMessage={this.props.errorMessage ? this.props.errorMessage : this.props.formFeedBack}
        id={"inp-" + this.props.field}
        resizable={this.props.resizable}
        autoAdjustHeight={this.props.autoAdjustHeight === true}
        label={label}
        onRenderLabel={this.props.customLabelRender != null ? this.props.customLabelRender : this.customLabelRender}
        name={this.props.field}
        className={className}
        value={this.props.value}
        placeholder={placeholder}
        onChange={(event) => this.textAreaOnChange(event)}
        multiline={true}
        rows={this.props.rows}
        allowFullScreen={true}
        required={this.props.required}
        readOnly={this.props.readOnly}
      />
    );
  }
}
export default WagxTextArea;