import * as actionTypes from "./action-types";

export const showSidePanel = (name) => {
  return {
    type: actionTypes.SHOW_SIDE_PANEL,
    name: name
  }
}

export const hideSidePanel = (name) => {
  return {
    type: actionTypes.HIDE_SIDE_PANEL,
    name: name
  }
}