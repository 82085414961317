import intl from 'react-intl-universal';
import { parseString, isNotNullOrEmpty } from './functions/StringUtils';
/*
<property>LocaleKeyObject: {
  <key>: <valueToBeTranslated>
*/

/*
titleLocaleKeyObject: {
  A_ESITO_SINISTRO: "CLV_ESITO_SINISTRO.{C_ESITO_SINISTRO}",
}

*/
export const getLocalizedProperty = (structure, property, defaultValue, object, formatterFieldMap) => {
  let obj = null;
  let text = null;
  const manipulatedDefaultValue = defaultValue != null ? defaultValue : "";
  let parseBeforeTranslate = false;

  if(structure != null) {
    if (object != null) {
      obj = {...object};
      const localeKeyObject = structure[property + "LocaleKeyObject"];
      const localeKeyObjectNoTranslate = structure[property + "LocaleKeyObjectNoTranslate"];
      if (localeKeyObject != null) {
        for(let key in localeKeyObject) {
          const stringToParse = localeKeyObject[key];
          const parsedString = parseString(stringToParse, object);
          if(isNotNullOrEmpty(parsedString)) {
            obj[key] = intl.get(parsedString).d(parsedString);
          }
        }
      }

      if (localeKeyObjectNoTranslate != null) {
        for(let key in localeKeyObjectNoTranslate) {
          const stringToParse = localeKeyObjectNoTranslate[key];
          obj[key] = parseString(stringToParse, object);
        }
      }
    }

    const currentLocale = intl.getInitOptions().currentLocale;
    parseBeforeTranslate = structure[property + "ParseBeforeTranslate"] === true;

    text = structure[property] != null
      ? structure[property]
      : manipulatedDefaultValue;

    if (structure[property + "_" + currentLocale]) {
      text = structure[property + "_" + currentLocale];
    } else if (structure[property + "LocaleKey"]) {
      let localeKey = structure[property + "LocaleKey"];

      if (parseBeforeTranslate === true) {
        localeKey = parseString(localeKey, obj, formatterFieldMap);
      }

      text = obj != null
        ? intl.get(localeKey, obj).d(text)
        : intl.get(localeKey).d(text);
    }
  }
  
  if (text == null) {
    text = manipulatedDefaultValue;
  }

  if (object != null && parseBeforeTranslate === false) {
    text = parseString(text, obj, formatterFieldMap);
  }

  return text;
}

export const extractCountry = (locale) => {
  if (locale != null) {
    if (locale.indexOf("-") !== -1) {
      return locale.split("-")[0];
    }
    if (locale.indexOf("_") !== -1) {
      return locale.split("_")[0];
    }
    return locale;
  }
  return "it";
}