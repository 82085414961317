import './WagxAdvSelectField.css';
import axios from '../../axios-wagx-web';
import axiosLib from "axios";
import React, { PureComponent } from 'react';
import { TagPicker, TextField } from 'office-ui-fabric-react';
import { manageError, parseString } from '../../functions/utility';
import intl from 'react-intl-universal';
import { isNullOrEmpty } from '../../functions/StringUtils';

class WagxAdvSelectField extends PureComponent {

  constructor(props) {

    super(props);

    //INIT CONSTANTS
    this.itemsUrl = "views/" + this.props.viewId + "/" + props.functionName;
    this.requestCancelToken = null;

    this.minChars = props.minChars != null ? props.minChars : 0;

    this.preselectedItems = [];
    this.preselectedKey = null;
    if (this.props.object && this.props.object[this.props.fieldMapping.id] != null && this.props.object[this.props.fieldMapping.id] !== "") {
      this.preselectedKey = this.props.object[this.props.fieldMapping.id];
      const name = parseString(this.props.fieldMapping.text, this.props.object);

      if (name != null && name !== "") {
        const preselectedOption = {
          key: this.props.object[this.props.fieldMapping.id],
          name: name,
        };
        this.preselectedItems.push(preselectedOption);
      }
    }

    this.state = {
      isLoading: false,
      options: [],
      items: {},
      selectedValue: this.props.defaultValue != null ? this.props.defaultValue : null,
      selectedItems: this.preselectedItems
    };
  }

  componentDidMount() {
    if (this.props.defaultValue != null && (this.props.value === "" || this.props.value == null || this.props.defaultValue === this.props.value)) {
      this.getDefaultItem(this.props.defaultValue);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const stateSelectedValue = this.state.selectedValue == null || this.state.selectedValue === ""
      ? null
      : ("" + this.state.selectedValue);
    const propsValue = this.props.value == null || this.props.value === ""
      ? null
      : ("" + this.props.value);

    if (this.props.value === this.preselectedKey && this.props.value !== prevProps.value) {
      this.setState({ selectedItems: this.preselectedItems });
    } else if (stateSelectedValue != null && propsValue !== stateSelectedValue) {
      this.setState({
        selectedValue: propsValue
      }, () => {
        this.getDefaultItem(this.state.selectedValue);
      })
    }
  }

  componentWillUnmount() {
    this.cleanRequestCancelToken();
  }

  cleanRequestCancelToken = () => {
    //Pulizia token Axios
    if (this.requestCancelToken != null) {
      this.requestCancelToken.cancel();
      this.requestCancelToken = null;
    }
  }

  getDefaultItem = (defaultValue) => {
    if (defaultValue == null) {
      return;
    }
    this.setState({
      isLoading: true
    }, () => {
      this.requestCancelToken = axiosLib.CancelToken.source();

      const postBody = {};
      postBody[this.props.fieldMapping.id] = defaultValue;

      axios.post(this.itemsUrl, postBody, { cancelToken: this.requestCancelToken.token })
        .then((response) => {
          let options = [];
          let items = {};
          if (response.data.success === true) {
            response.data.value.forEach(item => {
              const option = {
                key: item[this.props.fieldMapping.id],
                name: parseString(this.props.fieldMapping.text, item)
              };

              items[option.key] = item;

              if (this.preselectedItems != null && this.preselectedItems.length === 0) {
                if (this.props.defaultValue === defaultValue || this.preselectedKey === defaultValue) {
                  this.preselectedKey = defaultValue;
                  this.preselectedItems.push(option);
                }
              }

              options.push(option);
            });
          }

          this.setState({
            options: options,
            items: items,
            isLoading: false
          }, () => {
            this.onChangeHandler(this.state.options);
          });
          this.cleanRequestCancelToken();
        })
        .catch((error) => {
          if (!axiosLib.isCancel(error)) {
            manageError("WagxAdvSelectField", null, error);
          }

          this.setState({
            isLoading: false
          });

          this.cleanRequestCancelToken();
        });
    });
  }

  getItems = (value) => {
    return new Promise((resolve) => {
      if (this.requestCancelToken != null) {
        resolve(this.state.options);
      }

      this.requestCancelToken = axiosLib.CancelToken.source();

      const postBody = {};
      postBody[this.props.fieldMapping.search] = value;
      if (this.props.filters != null && this.props.object != null) {
        for (const key in this.props.filters) {
          postBody[key] = parseString(this.props.filters[key], this.props.object)
        }
      }

      axios.post(this.itemsUrl, postBody, { cancelToken: this.requestCancelToken.token })
        .then((response) => {
          let options = [];
          let items = {};
          if (response.data.success === true) {
            response.data.value.forEach(item => {
              const option = {
                key: item[this.props.fieldMapping.id],
                name: parseString(this.props.fieldMapping.text, item)
              };

              items[option.key] = item;

              options.push(option);
            });
          }

          this.setState({
            options: options,
            items: items
          }, () => { resolve(this.state.options) });
          this.cleanRequestCancelToken();
        })
        .catch((error) => {
          if (!axiosLib.isCancel(error)) {
            manageError("WagxAdvSelectField", null, error);
          }
          this.cleanRequestCancelToken();
          resolve([]);
        });
    });
  }

  onChangeHandler = (option) => {
    this.setState({ selectedItems: option }, () => {
      let curOption = [];
      let value = null;

      if (option && option.length === 1) {
        curOption = option[0];

        const key = curOption.key;
        const item = this.state.items[key];

        this.updateFieldsOnChange(key, item);

        value = key;
      } else {
        this.updateFieldsOnChange(null, undefined);
      }

      this.setState({
        selectedValue: value
      }, () => {
        this.props.onChange(null, this.props.field, value, null, this.props.formId);
      });

      return curOption;
    });
  }

  updateFieldsOnChange = (myValue, item) => {
    if (this.props.updateOnChange != null && this.props.updateFields != null) {
      const updatedObject = {};
      updatedObject[this.props.field] = myValue;
      for (const key in this.props.updateOnChange) {
        let value = null;

        if (item !== undefined) {
          value = parseString(this.props.updateOnChange[key], item);
        }

        updatedObject[key] = value;
      }

      this.props.updateFields(updatedObject);
    }
  }

  onResolveSuggestionsHandler = (value) => {
    if (this.props.fireOnChangeSuggestionValue === true && isNullOrEmpty(this.state.selectedValue)) {
      this.props.onChange(null, this.props.field, value, null, this.props.formId);
    }

    return new Promise((resolve) => {
      if (value != null && value !== "" && (value.length >= this.minChars)) {
        this.getItems(value).then((options) => {
          resolve(options);
        });
      } else {
        this.setState({
          options: [],
          items: {}
        }, () => { resolve(this.state.options) });
      }
    });
  }

  render() {
    const props = {
      itemLimit: 1,
      selectedItems: this.state.selectedItems,
      resolveDelay: this.props.delay != null ? this.props.delay : 500,
      onChange: this.onChangeHandler.bind(this),
      onResolveSuggestions: this.onResolveSuggestionsHandler.bind(this),
      disabled: this.props.disabled
    };

    let customLabel = null;
    if (this.props.onRenderLabel != null) {
      customLabel = this.props.onRenderLabel(this.props, null);
    } else {
      props.label = this.props.label;
    }

    const className = ["WagxAutocomplete"];
    if (this.props.formFeedBack) {
      className.push("invalidBox");
    }

    const renderElement =
      (
        this.state.isLoading
          ? (
            <TextField
              placeholder={intl.get("WagxAutocomplete.loading").d("Caricamento in Corso...")}
              disabled={true}
              readOnly={true}
              defaultValue={""}
            />
          )
          : (
            <TagPicker
              {...props}
            />
          )
      );

    return (
      <React.Fragment>
        {customLabel}
        <div className={className.join(" ")}>
          {renderElement}
        </div>
      </React.Fragment>
    )

  }

}

export default WagxAdvSelectField;