import * as actionTypes from "./action-types";
import axios from '../../axios-wagx-web';

export const updateSessionTimeout = (sessionTimeout) => {
  return {
    type: actionTypes.UPDATE_SESSION_TIMEOUT,
    sessionTimeout: sessionTimeout
  };
}

export const sessionWarningDialog = (show) => {
  return {
    type: actionTypes.SESSION_WARNING_DIALOG,
    show: show
  };
}

export const setSessionEnded = (sessionEnded) => {
  return {
    type: actionTypes.SET_SESSION_ENDED,
    sessionEnded: sessionEnded
  };
}

export const changeCurrentUser = (userId, history) => {
  return dispatch => {
    dispatch(currentUserChanging())
    axios.put("/changeCurrentUser/" + userId)
      .then(response => {
        if(response && response.data && response.data.success === true){          
          dispatch(updateAllViewOnUserChanged(response.data.value));
          dispatch(currentUserChanged(userId));
        }
      })
      .catch(error => {

      });
  }
}

export const updateAllViewOnUserChanged = (viewMap) =>{
  return{
    type: actionTypes.UPDATE_ALL_VIEW_ON_USER_CHANGED,
    viewMap: viewMap
  }
}

export const currentUserChanging = () => {
  return {
    type: actionTypes.CURRENT_USER_CHANGING
  }
}

export const currentUserChanged = (userId) => {
  return {
    type: actionTypes.CURRENT_USER_CHANGED,
    userId: userId
  };
}