import * as actionTypes from '../actions/action-types';
import { updateObject } from '../utils';
import { syncedReducer } from 'redux-sync-reducer';

const initialState = {
  isAuthenticated: false,
  showSessionWarningDialog: false,
  sessiontimeout: null,
  sessionEnded: false,
  currentUserChanging: false,
  currentUserChangedId: null
};

const loginSuccess = (state, action) => {
  return updateObject(state, { isAuthenticated: true });
}

const updateSessionTimeout = (state, action) => {
  return updateObject(state, { sessiontimeout: action.sessionTimeout });
}

const sessionWarningDialog = (state, action) => {
  return updateObject(state, { showSessionWarningDialog: action.show });
}

const setSessionEnded = (state, action) => {
  return updateObject(state, { sessionEnded: action.sessionEnded });
}

const logout = (state, action) => {
  return initialState;
}

const changeCurrentUser = (state, action) => {
  const updatedState = Object.assign({}, state);

  updatedState.currentUserChanging = false;
  updatedState.currentUserChangedId = action.userId;

  return updateObject(state, updatedState);
}

const currentUserChangingStarted = (state, action) => {
  const updatedState = Object.assign({}, state);
  updatedState.currentUserChanging = true;
  return updateObject(state, updatedState);
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS: return loginSuccess(state, action);
    case actionTypes.UPDATE_SESSION_TIMEOUT: return updateSessionTimeout(state, action);
    case actionTypes.SESSION_WARNING_DIALOG: return sessionWarningDialog(state, action);
    case actionTypes.SET_SESSION_ENDED: return setSessionEnded(state, action);
    case actionTypes.CURRENT_USER_CHANGED: return changeCurrentUser(state, action);
    case actionTypes.CURRENT_USER_CHANGING: return currentUserChangingStarted(state, action);
    case actionTypes.LOGOUT: return logout(state, action);
    default:
      return state;
  }
};

export default function f(syncName) {console.log(syncName); return syncedReducer(reducer,{name: syncName}) };