import { updateObject } from '../utils'
import * as actionTypes from '../actions/action-types';

const initialState = {
  curStartInterval: null,
  isFlipped: false,
  selectedCalendarData: null
};

const setCurStartInterval = (state, action) => {
  return updateObject(state, { curStartInterval: action.calendar });
}
const setIsFlipped = (state, action) => {
  return updateObject(state, { isFlipped: action.calendar });
}
const setSelectedCalendarData = (state, action) => {
  return updateObject(state, { selectedCalendarData: action.calendar });
}
const logout = (state, action) => {
  return updateObject(state, initialState);
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGOUT: return logout(state, action);
    case actionTypes.SET_CUR_START_INTERVAL: return setCurStartInterval(state, action);
    case actionTypes.SET_IS_FLIPPED: return setIsFlipped(state, action);
    case actionTypes.SET_SELECTED_CALENDAR_DATA: return setSelectedCalendarData(state, action);
    default:
      return state;
  }
};

export default reducer;