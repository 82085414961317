import { updateObject } from '../utils'
import * as actionTypes from '../actions/action-types';

const initialState = {
  openedSidePanel: []
};

const showSidePanel = (state, action) => {
  const openedSidePanel = state.openedSidePanel;
  if (openedSidePanel.indexOf(action.name) === -1) {
    openedSidePanel.push(action.name);
  }
  return updateObject(state, {
    openedSidePanel: [...openedSidePanel]
  });
}

const hideSidePanel = (state, action) => {
  let openedSidePanel = state.openedSidePanel;
  const pos = openedSidePanel.indexOf(action.name)
  if (pos !== -1) {
    openedSidePanel.splice(pos, 1);
  }
  return updateObject(state, {
    openedSidePanel: [...openedSidePanel]
  });
}

const logout = (state, action) => {
  return updateObject(state, { ...initialState });
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_SIDE_PANEL: return showSidePanel(state, action);
    case actionTypes.HIDE_SIDE_PANEL: return hideSidePanel(state, action);
    case actionTypes.LOGOUT: return logout(state, action);
    default:
      return state;
  }
};

export default reducer;