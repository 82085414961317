import { Icon } from 'office-ui-fabric-react';

export const MessageSeverityEnum = {
  ERROR: {
    key: "error",
    titleKey: "MessageSeverityEnum.error",
    titleDefault: "Si sono verificati i seguenti errori",
    messageKey: "genericError",
    icon: <div className="error"><Icon iconName="StatusCircleErrorX" /></div>,
    priority: 1
  },
  WARNING: {
    key: "warning",
    titleKey: "MessageSeverityEnum.warning",
    titleDefault: "Punti di Attenzione",
    messageKey: "genericWarning",
    icon: <div className="warning"><Icon iconName="StatusCircleExclamation" /></div>,
    priority: 2
  },
  SUCCESS: {
    key: "success",
    titleKey: "MessageSeverityEnum.success",
    titleDefault: "Messaggio dal Sistema",
    messageKey: "genericSuccess",
    icon: <div className="success"><Icon iconName="StatusCircleCheckmark" /></div>,
    priority: 3
  },
  INFO: {
    key: "info",
    titleKey: "MessageSeverityEnum.info",
    titleDefault: "Messaggio dal Sistema",
    messageKey: "genericInfo",
    icon: <div className="info"><Icon iconName="StatusCircleInfo" /></div>,
    priority: 4
  }
}

export const getMessageSeverityEnumByKey = (enumKey) => {
  const enumObj = Object.keys(MessageSeverityEnum).find((enumObj) => MessageSeverityEnum[enumObj].key === enumKey);

  if (enumObj != null) {
    return MessageSeverityEnum[enumObj];
  }
}
